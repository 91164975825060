import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import { UserProvider, useUser } from "../utils/contexts/UserContext";
import { AuthProvider } from "../utils/contexts/AuthContext";
import { DarkModeProvider } from "../utils/contexts/theme.context";
import { Toaster } from "react-hot-toast";
import NotFoundPage from "../pages/NotFound";
import HomePage from "../pages/App/dashboards/D0_Home";
import UploadSAFTPage from "../pages/App/sales/UploadSAFT";
import General from "../pages/App/dashboards/D1_General";
import OwnBasketPage from "../pages/App/dashboards/D2_OwnBasket";
import FoodCostPageRecipes from "../pages/App/dashboards/FoodCostRecipes";
import FoodCostPageRecipesTable from "../pages/App/dashboards/D3_FoodCostRecipesTable";
import ChangePasswordPage from "../pages/Profile/ChangePassword";
import SalesPageShortTerm from "../pages/App/sales/ShortTerm";
import SalesPageLongTerm from "../pages/App/sales/LongTerm";
import ForecastAnalysis from "../pages/App/forecast/ForecastAnalysis";
import MarginAnalysis from "../pages/App/dashboards/D6_MarginAnalysis";
import FoodCostAnalysis from "../pages/App/dashboards/D5_FoodCostAnalysis";
import FoodCostRecipes from "../pages/App/dashboards/D3_FoodCostRecipes";
import FoodCostIngredients from "../pages/App/dashboards/D3_FoodCostIngredients";
import ForecastView from "../pages/App/forecast/ForecastView";
import Planning from "../pages/App/forecast/Planning";
import RecipeListPage from "../pages/App/menu/recipes/RecipeList";
import RecipeTec from "../pages/App/menu/recipes/RecipeTechnical";
import RecipeDetail from "../pages/App/menu/recipes/RecipeDetail";
import IngredDetailPage from "../pages/App/menu/ingredients/IngredientDetail";
import CustomersAnalysis from "../pages/App/sales/Customers";
import CustomerView from "../pages/App/sales/CustomerView";
import RestSelectProdsPage from "../pages/App/user/RestSelectProds";
import RestSettings from "../pages/App/user/RestSettings";
import IngredCreate from "../pages/App/menu/ingredients/IngredCreate";
import ReprocessarIngreds from "../pages/App/menu/ingredients/IngredReprocess";
import ReprocessarReceitas from "../pages/App/menu/recipes/RecipeReprocess";
import Validate from "../pages/App/menu/recipes/RecipesValidate";
import TicketList from "../pages/App/other/tickets/ticket-list";
import TicketDetail from "../pages/App/other/tickets/ticket-detail";
import Pricing from "../pages/App/menu/pricing/Pricing";
import PlanningCreate from "../pages/App/forecast/PlaningCreate";
import EventsCreate from "../pages/App/other/events/EventsList";
import Custom from "../pages/App/forecast/CustomPlaning";
import DataFilling from "../pages/App/other/data_filling/DataFilling";
import QueriesPage from "../pages/App/other/queries/Queries";
import IndividualQuery from "../pages/App/other/queries/Query";
import { useEffect, useState } from "react";
import "../index.css";
import Sidebar from "../components/reusable/Sidebar";
import { SidebarProvider, useSidebar } from "../utils/contexts/SideBarContext";

// User Onboarding
import UserSetup from "../pages/App/user/initialSetup/UserSetup";
import SignUp from "../pages/Auth/SignUp";
import SignIn from "../pages/Auth/SignIn";
import PricingTable from "../pages/App/user/PricingTable";

function App() {
  // Page layout
  const Layout = ({ children }: { children: any }) => {
    const { user, profile, restaurant } = useUser();
    const { sidebarOpen, setSidebarOpen } = useSidebar();
    const navigate = useNavigate();

    useEffect(() => {
      const initializeUser = async () => {
        // nao e necessario pois o user ja e inicializado no contexto e isto apenas cria pedidos duplicados
        /*if (!user) {
          await fetchUser();
        }*/
        // falta testar isto
        if (user && (!profile || !restaurant)) {
          navigate("/user-setup");
        }
      };
    
      initializeUser();
    }, [user]);

    return (
      <div className="w-full bg-zinc-100 transition-all duration-700 z-0">
        <Sidebar
          isSidebarOpen={sidebarOpen}
          setIsSidebarOpen={setSidebarOpen}
        />
        {user && (
          <div className={`${sidebarOpen ? "pl-72" : "pt-32"} overflow-auto`}>
            <div className="text-[var(--black)]">{children}</div>
          </div>
        )}
      </div>
    );
  };

  return (
    <BrowserRouter>
      <AuthProvider>
        <UserProvider>
          <DarkModeProvider>
            <SidebarProvider>
              <Toaster />
                <Routes>
                  <Route index element={<SignIn />} />
                  <Route path="/signup" element={<SignUp />} />
                  <Route path="/user-setup" element={<UserSetup />} />
                  <Route
                    path="/payments"
                    element={
                      <Layout>
                        <PricingTable />
                      </Layout>
                    }
                  />
                  <Route
                    path="/app/dataFilling"
                    element={
                      <Layout>
                        <DataFilling />
                      </Layout>
                    }
                  />
                  <Route
                    path="/app/homepage"
                    element={
                      <Layout>
                        <HomePage />
                      </Layout>
                    }
                  />
                  <Route
                    path="/app/general"
                    element={
                      <Layout>
                        <General />
                      </Layout>
                    }
                  />
                  <Route
                    path="/app/ownbasket"
                    element={
                      <Layout>
                        <OwnBasketPage />
                      </Layout>
                    }
                  />
                  <Route
                    path="/app/tickets"
                    element={
                      <Layout>
                        <TicketList />
                      </Layout>
                    }
                  />
                  <Route
                    path="/app/tickets/detail/:id"
                    element={
                      <Layout>
                        <TicketDetail />
                      </Layout>
                    }
                  />
                  <Route
                    path="/app/foodcost/recipes"
                    element={
                      <Layout>
                        <FoodCostPageRecipes />
                      </Layout>
                    }
                  />
                  <Route
                    path="/app/events"
                    element={
                      <Layout>
                        <EventsCreate />
                      </Layout>
                    }
                  />
                  <Route
                    path="/app/foodcost/recipesTable"
                    element={
                      <Layout>
                        <FoodCostPageRecipesTable />
                      </Layout>
                    }
                  />
                  <Route
                    path="/app/uploadsaft"
                    element={
                      <Layout>
                        <UploadSAFTPage />
                      </Layout>
                    }
                  />
                  <Route
                    path="/app/profile"
                    element={
                      <Layout>
                        <RestSettings />
                      </Layout>
                    }
                  />
                  <Route
                    path="/app/profile/changePassword"
                    element={
                      <Layout>
                        <ChangePasswordPage />
                      </Layout>
                    }
                  />
                  <Route
                    path="/app/sales/short"
                    element={
                      <Layout>
                        <SalesPageShortTerm />
                      </Layout>
                    }
                  />
                  <Route
                    path="/app/sales/long"
                    element={
                      <Layout>
                        <SalesPageLongTerm />
                      </Layout>
                    }
                  />
                  <Route
                    path="/app/forecasts"
                    element={
                      <Layout>
                        <ForecastAnalysis />
                      </Layout>
                    }
                  />
                  <Route
                    path="/app/forecasts/forecast"
                    element={
                      <Layout>
                        <ForecastView />
                      </Layout>
                    }
                  />
                  <Route
                    path="/app/customers"
                    element={
                      <Layout>
                        <CustomersAnalysis />
                      </Layout>
                    }
                  />
                  <Route
                    path="/app/customers/customer"
                    element={
                      <Layout>
                        <CustomerView />
                      </Layout>
                    }
                  />
                  <Route
                    path="/app/margin"
                    element={
                      <Layout>
                        <MarginAnalysis />
                      </Layout>
                    }
                  />
                  <Route
                    path="/app/foodcostAnalysis"
                    element={
                      <Layout>
                        <FoodCostAnalysis />
                      </Layout>
                    }
                  />
                  <Route
                    path="/app/foodcost/recipes"
                    element={
                      <Layout>
                        <FoodCostRecipes />
                      </Layout>
                    }
                  />
                  <Route
                    path="/app/foodcost/recipes/ingr"
                    element={
                      <Layout>
                        <FoodCostIngredients />
                      </Layout>
                    }
                  />
                  <Route
                    path="/app/pricing"
                    element={
                      <Layout>
                        <Pricing />
                      </Layout>
                    }
                  />
                  <Route
                    path="/app/consultas"
                    element={
                      <Layout>
                        <QueriesPage />
                      </Layout>
                    }
                  />
                  <Route
                    path="/app/consultas/consulta"
                    element={
                      <Layout>
                        <IndividualQuery />
                      </Layout>
                    }
                  />
                  <Route
                    path="/app/planning"
                    element={
                      <Layout>
                        <Planning />
                      </Layout>
                    }
                  />
                  <Route
                    path="/app/planning/create"
                    element={
                      <Layout>
                        <PlanningCreate />
                      </Layout>
                    }
                  />
                  <Route
                    path="/app/planning/custom/:id"
                    element={
                      <Layout>
                        <Custom />
                      </Layout>
                    }
                  />
                  <Route
                    path="/app/menu"
                    element={
                      <Layout>
                        <RecipeListPage />
                      </Layout>
                    }
                  />
                  <Route
                    path="/app/menu/ingred/create"
                    element={
                      <Layout>
                        <IngredCreate />
                      </Layout>
                    }
                  />
                  <Route
                    path="/app/menu/reprocess_ingreds"
                    element={
                      <Layout>
                        <ReprocessarIngreds />
                      </Layout>
                    }
                  />
                  <Route
                    path="/app/menu/reprocess_recipes"
                    element={
                      <Layout>
                        <ReprocessarReceitas />
                      </Layout>
                    }
                  />
                  <Route
                    path="/app/menu/recipe"
                    element={
                      <Layout>
                        <RecipeDetail />
                      </Layout>
                    }
                  />
                  <Route
                    path="/app/menu/recipe/tec"
                    element={
                      <Layout>
                        <RecipeTec />
                      </Layout>
                    }
                  />
                  <Route
                    path="/app/menu/ingredient"
                    element={
                      <Layout>
                        <IngredDetailPage />
                      </Layout>
                    }
                  />
                  <Route
                    path="/app/menu/rest-select-prods"
                    element={
                      <Layout>
                        <RestSelectProdsPage />
                      </Layout>
                    }
                  />
                  <Route
                    path="/app/menu/validate_recipes"
                    element={
                      <Layout>
                        <Validate />
                      </Layout>
                    }
                  />
                  <Route path="*" element={<NotFoundPage />} />
                </Routes>
            </SidebarProvider>
          </DarkModeProvider>
        </UserProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
