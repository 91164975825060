// React
import { useEffect, useRef, useState } from "react";

// Hooks
import useTokenVerifier from "../../../../utils/hooks/useTokenVerifier";
import useAxiosPrivate from "../../../../utils/hooks/useAxiosPrivate";
import useDarkMode from "../../../Profile/usedarkmode";

// Components
import Page from "../../page";
import InfoComponent from "../../../../components/reusable/info/info.component";
import Loading from "../../../../components/reusable/loading/loading";
import Paginate from "../../../../components/reusable/pagination/paginate";
import Toaster from "../../../../components/reusable/Toaster";
import { Button } from "../../../../components/ui/button";

// Icons
import { IoRestaurant } from "react-icons/io5";
import Modal from "../../../../components/reusable/Modal";
import { DatePicker } from "../../../../components/ui/datePicker";

// Styles
// (No styles imported in this selection)

const IngredReprocessPage = () => {
  const [tokenVerified, setTokenVerified] = useState(false);

  // Callback function to update token verification status
  const handleTokenVerified = () => {
    setTokenVerified(true);
  };
  useTokenVerifier({
    onTokenVerified: handleTokenVerified,
    log: "MENU CREATE REPROCESS INGREDS PAGE",
  });

  const axiosPrivate = useAxiosPrivate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [change, setChange] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [date, setDate] = useState(null);
  const [selectedIngredients, setSelectedIngredients] = useState([]);
  const [currentPageIngreds, setCurrentPageIngreds] = useState(1);
  const [totalPagesIngreds, setTotalPagesIngreds] = useState(1);

  const today = new Date();
  today.setHours(23, 59, 59, 0);

  function convertDateFormat(inputDate: string): string {
    // Parse the input date string
    const dateObj = new Date(inputDate);

    // Extract year, month, and day components
    const year = dateObj.getFullYear().toString();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0"); // Month starts from 0
    const day = dateObj.getDate().toString().padStart(2, "0");

    // Form the desired output format
    const outputDate = year + month + day;

    return outputDate;
  }

  useEffect(() => {
    setLoading(true);
    let isMounted = true;
    const controller = new AbortController();

    const getGraph = async (url) => {
      try {
        const response = await axiosPrivate.get(url, {
          signal: controller.signal,
        });
        setData(response.data.results.data);
        setTotalPagesIngreds(response.data.results.total_pages);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };

    const apiUrl = `ingred_list?${currentPageIngreds}`;

    getGraph(apiUrl);

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [change, currentPageIngreds]);

  const handleReprocessAll = async () => {
    setLoading(true);
    if (date == null) {
      Toaster.show(
        "Selecione uma data para reprocessar os ingredientes.",
        "error"
      );
      setLoading(false);
      return;
    }

    try {
      const response = await axiosPrivate.post(
        `/reprocess_ingreds/`,
        JSON.stringify({ start_date: convertDateFormat(date), ingred_id: [] })
      );

      if (response.status === 200 || response.status === 201) {
        Toaster.show("Reprocessamento efetuado com sucesso.", "success");
        setLoading(false);
      } else {
        Toaster.show(
          "Erro ao reprocesar ingredientes. Por favor, tente mais tarde.",
          "error"
        );
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      Toaster.show(
        "Erro ao reprocesar ingredientes. Por favor, tente mais tarde.",
        "error"
      );
      setLoading(false);
    }
  };

  const handleReprocessSelected = async () => {
    setLoading(true);
    if (date == null) {
      Toaster.show(
        "Selecione uma data para reprocessar os ingredientes.",
        "error"
      );
      setLoading(false);
      return;
    }

    if (selectedIngredients.length === 0) {
      Toaster.show(
        "Selecione pelo menos um ingrediente para reprocessar.",
        "error"
      );
      setLoading(false);
      return;
    }

    try {
      const response = await axiosPrivate.post(
        `/reprocess_ingreds/`,
        JSON.stringify({
          start_date: convertDateFormat(date),
          ingred_id: selectedIngredients.map((ingredient) => ingredient.id),
        })
      );

      if (response.status === 200 || response.status === 201) {
        Toaster.show("Reprocessamento efetuado com sucesso.", "success");
        setLoading(false);
      } else {
        Toaster.show(
          "Erro ao reprocesar ingredientes. Por favor, tente mais tarde.",
          "error"
        );
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      Toaster.show(
        "Erro ao reprocesar ingredientes. Por favor, tente mais tarde.",
        "error"
      );
      setLoading(false);
    }
  };

  const darkMode = useDarkMode();

  function formatDate(dateString, hour = true) {
    const date = new Date(dateString);
    if (!hour) {
      const formattedDate = date.toLocaleDateString("pt-PT");
      return formattedDate;
    }
    const formattedDate = date.toLocaleDateString("pt-PT", {
      hour: "numeric",
      minute: "numeric",
    });
    return formattedDate;
  }

  const handleCheckboxChange = (ingr_id) => {
    setSelectedIngredients((prevSelectedIngredients) => {
      if (prevSelectedIngredients.find((item) => item.id === ingr_id)) {
        return prevSelectedIngredients.filter((item) => item.id !== ingr_id);
      } else {
        return [...prevSelectedIngredients, { id: ingr_id }];
      }
    });
  };

  return (
    <Page title={"Reprocessar Ingredientes"} icon={<IoRestaurant size={32} />}>
      {tokenVerified ? (
        <div>
          <div style={{ padding: "70px", paddingTop: "50px" }}>
            <div>
              <div>
                <p
                  style={{ color: darkMode ? "white" : "black" }}
                  className="text-4xl font-semibold mt-10 text-blue-dark"
                >
                  Reprocessar ingredientes
                </p>
              </div>
            </div>
            <div style={{ padding: "30px", paddingTop: "20px" }}>
              <div>
                <div>
                  <Button onClick={() => setShowModal(true)}>
                    {date != null
                      ? `Selecionada a data: ${formatDate(date, false)}`
                      : "Selecione uma data"}
                  </Button>
                </div>
                <div>
                  <Button
                    onClick={() =>
                      Toaster.showWithHandler(
                        date == null
                          ? "Estará a alterar valores calculados a partir de hoje para todos os ingredientes"
                          : `Estará a alterar valores calculados a partir de: ${formatDate(
                              date,
                              false
                            )} para todos os ingredientes`,
                        () => handleReprocessAll(),
                        { duration: Infinity }
                      )
                    }
                    id="update-all"
                  >
                    Atualizar todos
                  </Button>
                </div>
                <div>
                  <button
                    onClick={() =>
                      Toaster.showWithHandler(
                        date == null
                          ? "Estará a alterar valores calculados a partir de hoje para os ingredientes selecionados"
                          : `Estará a alterar valores calculados a partir de: ${formatDate(
                              date,
                              false
                            )} para os ingredientes selecionados`,
                        () => handleReprocessSelected(),
                        { duration: Infinity }
                      )
                    }
                    id="update-selected"
                  >
                    Atualizar selecionados
                  </button>
                  <button id="update-selected"></button>
                </div>
              </div>
              <InfoComponent text="">
                <div>
                  <p>
                    Poderá reprocessar um ou mais ingredientes atualizando
                    valores previamente calculados segundo as suas alterações.
                    Poderá aplicar as alterações a todos ou apenas aos
                    ingredientes selecionados.
                  </p>
                  <p>
                    A data selecionada irá indicar o ponto a partir da qual as
                    alterações terão efeito.
                  </p>
                </div>
              </InfoComponent>

              {data.length > 0 && (
                <div>
                  <table className="min-w-full divide-y divide-gray-200 shadow-2xl rounded">
                    <thead className="bg-blue-dark text-white rounded">
                      <tr>
                        <th
                          style={{ background: darkMode ? "#422655" : "" }}
                          className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider"
                        >
                          Nome
                        </th>
                        <th
                          style={{ background: darkMode ? "#422655" : "" }}
                          className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider"
                        >
                          Classe
                        </th>
                        <th
                          style={{ background: darkMode ? "#422655" : "" }}
                          className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider"
                        >
                          Descrição
                        </th>
                        <th
                          style={{ background: darkMode ? "#422655" : "" }}
                          className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider"
                        >
                          Tipo
                        </th>
                        <th
                          style={{ background: darkMode ? "#422655" : "" }}
                          className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider"
                        >
                          Data Criação
                        </th>
                        <th
                          style={{ background: darkMode ? "#422655" : "" }}
                          className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider"
                        >
                          Selecionar
                        </th>
                      </tr>
                    </thead>
                    <tbody
                      style={{ backgroundColor: darkMode ? "#262853" : "" }}
                      className="bg-white divide-y divide-gray-200"
                    >
                      {data.map((ingredient) => (
                        <tr key={ingredient.ingr_id}>
                          <td
                            style={{ color: darkMode ? "white" : "black" }}
                            className="px-6 py-2 whitespace-no-wrap text-gray-600"
                          >
                            {ingredient.ingr_name}
                          </td>
                          <td
                            style={{ color: darkMode ? "white" : "black" }}
                            className="px-6 py-2 whitespace-no-wrap text-gray-600"
                          >
                            {ingredient.ingr_class}
                          </td>
                          <td
                            style={{ color: darkMode ? "white" : "black" }}
                            className="px-6 py-2 whitespace-no-wrap text-gray-600"
                          >
                            {ingredient.ingr_descrip}
                          </td>
                          <td
                            style={{ color: darkMode ? "white" : "black" }}
                            className="px-6 py-2 whitespace-no-wrap text-gray-600"
                          >
                            {ingredient.ingr_degree_process}
                          </td>
                          <td
                            style={{ color: darkMode ? "white" : "black" }}
                            className="py-2 whitespace-no-wrap text-gray-600"
                          >
                            {formatDate(ingredient.ztimestamp)}
                          </td>
                          <td
                            style={{ color: darkMode ? "white" : "black" }}
                            className="px-6 py-2 whitespace-no-wrap text-gray-600"
                          >
                            <input
                              type="checkbox"
                              checked={
                                selectedIngredients.find(
                                  (item) => item.id === ingredient.ingr_id
                                ) !== undefined
                              }
                              onChange={() =>
                                handleCheckboxChange(ingredient.ingr_id)
                              }
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "20px",
              }}
            >
              <Paginate
                currentPage={currentPageIngreds}
                setCurrentPage={setCurrentPageIngreds}
                totalPages={totalPagesIngreds}
                loading={loading}
              />
            </div>
          </div>

          <Modal isOpen={showModal} setIsOpen={setShowModal}>
            <div style={{ height: "100%", margin: "0px" }}>
              <div style={{ height: "100%" }}>
                <div style={{ height: "100%" }}>
                <DatePicker
                      date={date}
                      setDate={setDate}
                      maxDate={today}
                    />
                </div>
              </div>
            </div>
          </Modal>

          <Loading isOpen={loading} />
        </div>
      ) : (
        <Loading isOpen={true} />
      )}
    </Page>
  );
};

export default IngredReprocessPage;
