// React
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// contexts
import { DarkModeProvider } from "../../../utils/contexts/theme.context";

// components
import Page from "../page";
import Loading from "../../../components/reusable/loading/loading";
import Toaster from "../../../components/reusable/Toaster";
import useTokenVerifier from "../../../utils/hooks/useTokenVerifier";
import useDarkMode from "../../Profile/usedarkmode";
import { useApi } from "../../../utils/hooks/useAxiosPrivate";
import { Button } from "../../../components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
} from "../../../components/ui/dialog";
import {
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card";

// icons / images
import { MdSearch } from "react-icons/md";
import { Label } from "../../../components/ui/label";
import { Input } from "../../../components/ui/input";

const initialColumns = [
  { headerName: "Descritivo Customer", field: "an_descr" },
  { headerName: "Estado", field: "an_obs" },
  { headerName: "Data de início", field: "an_dt_ini" },
  { headerName: "Data de fim", field: "an_dt_fim" },
  { headerName: "", field: "" },
];

const initialNewCustomerData = {
  name: "",
  description: "",
  start: "",
  end: "",
};

const CustomersAnalysis = () => {
  const [tokenVerified, setTokenVerified] = useState(false);
  const navigate = useNavigate();

  const handleTokenVerified = () => {
    setTokenVerified(true);
  };
  useTokenVerifier({
    onTokenVerified: handleTokenVerified,
    log: "CUSTOMERS PAGE",
  });

  const { get, post } = useApi();
  const darkMode = useDarkMode();

  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newCustomerData, setNewCustomerData] = useState(
    initialNewCustomerData
  );

  useEffect(() => {
    const controller = new AbortController();

    const fetchData = async () => {
      try {
        const response = await get("list_analysis", {
          signal: controller.signal,
        });
        setCustomers(response.data);
      } catch (error) {
        console.error(error);
        Toaster.show("Erro ao carregar análises de consumidor", "error");
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    return () => controller.abort();
  }, []);

  const handleCreateCustomer = async () => {
    const newCustomer = {
      an_descr: newCustomerData.name,
      an_obs: "Em progresso...",
      an_dt_ini: newCustomerData.start,
      an_dt_fim: newCustomerData.end,
    };
    setCustomers((prevCustomers) => [...prevCustomers, newCustomer]);

    try {
      await post(
        `customer_analisys/?name=${newCustomerData.name}&description=${newCustomerData.description}&start=${newCustomerData.start}&end=${newCustomerData.end}`,
        JSON.stringify(newCustomerData)
      );
      Toaster.show("Análise de consumidor criada com sucesso!", "success");
    } catch (error) {
      console.error("Erro ao criar análise:", error);
      Toaster.show("Erro ao criar análise de consumidor", "error");
    }

    setNewCustomerData(initialNewCustomerData);
  };

  const renderCustomerTable = () => (
    <Table>
      <TableHeader>
        <TableRow>
          {initialColumns.map((column) => (
            <TableCell key={column.field}>{column.headerName}</TableCell>
          ))}
        </TableRow>
      </TableHeader>
      <TableBody>
        {customers.map((item, index) => (
          <TableRow
            key={index}
            onClick={() => navigate(`/app/customers/customer?id=${item.an_id}`)}
          >
            <TableCell>{item.an_descr}</TableCell>
            <TableCell>{item.an_obs}</TableCell>
            <TableCell>{item.an_dt_ini}</TableCell>
            <TableCell>{item.an_dt_fim}</TableCell>
            <TableCell>
              {item.an_obs === "Disponível" ? <MdSearch size={24} /> : ""}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );

  return (
    <Page>
      {tokenVerified ? (
        <DarkModeProvider>
          <div className="w-[80%] mx-auto">
            <div className="flex justify-between items-center mb-5">
              <CardHeader>
                <CardTitle>Análise de Consumidores</CardTitle>
                <CardDescription>
                  Crie e gerencie suas análises de consumidores
                </CardDescription>
              </CardHeader>
              <Dialog>
                <DialogTrigger asChild>
                  <Button className="mt-5">+ Criar Análise</Button>
                </DialogTrigger>
                <DialogContent className="min-w-[800px] max-h-[800px] overflow-auto">
                  <DialogTitle>Criar Nova Análise de Consumidor</DialogTitle>
                  <DialogDescription>
                    Preencha os campos para criar uma nova análise de consumidor
                  </DialogDescription>
                  <div className="flex flex-col gap-10 mt-4">
                    <div>
                      <Label className="block text-sm font-medium text-gray-700">
                        Nome
                      </Label>
                      <Input
                        type="text"
                        placeholder="Nome da Análise"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        value={newCustomerData.name}
                        onChange={(e) =>
                          setNewCustomerData({
                            ...newCustomerData,
                            name: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div>
                      <Label className="block text-sm font-medium text-gray-700">
                        Descrição
                      </Label>
                      <textarea
                        placeholder="Descrição da Análise"
                        className="w-full px-4 pt-2 rounded-md border border-gray bg-white"
                        value={newCustomerData.description}
                        onChange={(e) =>
                          setNewCustomerData({
                            ...newCustomerData,
                            description: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="flex gap-4">
                      <div className="flex-1">
                        <Label className="block text-sm font-medium text-gray-700">
                          Data de início
                        </Label>
                        <Input
                          type="date"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                          value={newCustomerData.start}
                          onChange={(e) =>
                            setNewCustomerData({
                              ...newCustomerData,
                              start: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="flex-1">
                        <Label className="block text-sm font-medium text-gray-700">
                          Data de fim
                        </Label>
                        <Input
                          type="date"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                          value={newCustomerData.end}
                          onChange={(e) =>
                            setNewCustomerData({
                              ...newCustomerData,
                              end: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end gap-2">
                    <Button
                      onClick={handleCreateCustomer}
                      disabled={
                        !newCustomerData.name || !newCustomerData.description
                      }
                    >
                      Criar
                    </Button>
                  </div>
                </DialogContent>
              </Dialog>
            </div>
            <div>
              {customers && customers.length > 0 ? (
                renderCustomerTable()
              ) : (
                <div
                  style={{ color: darkMode ? "white" : "black" }}
                  className="text-center text-2xl mt-10"
                >
                  Sem análises de consumidor. Crie uma nova análise.
                </div>
              )}
            </div>
            <Loading isOpen={loading} />
          </div>
        </DarkModeProvider>
      ) : (
        <Loading isOpen={true} />
      )}
    </Page>
  );
};

export default CustomersAnalysis;
