// Hooks
import { darkMode, toggleDarkModeHandler } from "../../pages/Profile/Settings";

// Icons
import { FaMoon, FaSun } from "react-icons/fa";
import { IoPerson } from "react-icons/io5";
import { IoClose } from "react-icons/io5";

// Components
import ProfileMenu from "../profileMenu";
import { useState } from "react";

const ProfileComponent = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="text-black">
      <div className="flex gap-14">
        <div className="flex gap-2 items-center">
          <div className="mx-2 cursor-pointer" onClick={toggleDarkModeHandler}>
            {darkMode === true ? <FaMoon size={24} /> : <FaSun size={24} />}
          </div>
        </div>
        <div
          onClick={toggleDropdown}
          className="cursor-pointer p-4 rounded-full hover:bg-zinc-200"
        >
          {!isDropdownOpen ? <IoPerson size={24} /> : <IoClose size={24} />}
        </div>
      </div>
      <div id="dropDown" className="relative">
        <div
          className={`absolute text-zinc-500 bg-transparent right-0 mt-5 w-60 rounded-xl shadow-lg overflow-hidden transition-all duration-700 ease-in-out ${
            isDropdownOpen ? "max-h-[30rem] opacity-100" : "max-h-0 opacity-0"
          }`}
        >
          <ProfileMenu />
        </div>
      </div>
    </div>
  );
};

export default ProfileComponent;
