import React from "react";
import { useNavigate } from "react-router-dom";
import "./back.styles.css";
import useDarkMode from "../../../pages/Profile/usedarkmode";
import { IoArrowBackCircleOutline } from "react-icons/io5";

const BackComponent = () => {
  const darkMode = useDarkMode();
  const history = useNavigate();

  const goBack = () => {
    history(-1);
  };
  return (
    <div>
      <IoArrowBackCircleOutline
        size={32}
        onClick={goBack}
        className="goBackIcon"
        style={{ color: darkMode ? "white" : "black" }}
      />
    </div>
  );
};

export default BackComponent;
