import { createContext, ReactNode, useState, Dispatch, SetStateAction } from "react";

export interface AuthContextProps {
  auth: {
      //user: string;
      //pwd: string;
      //roles: string[]; // Change to a list of strings
      accessToken: string;
      // Add other properties if needed
  };
  setAuth: Dispatch<SetStateAction<{
      //user: string;
      //pwd: string;
      //roles: string[]; // Change to a list of strings
      accessToken: string;
      // Add other properties if needed
  }>>;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [auth, setAuth] = useState({
      //user: "",
      //pwd: "",
      //roles: [], // Initialize as an empty array
      accessToken: "",
      // Initialize other properties with default values if needed
  });

  return (
      <AuthContext.Provider value={{ auth, setAuth }}>
          {children}
      </AuthContext.Provider>
  );
};

export default AuthContext;