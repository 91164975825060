// React
import React, { useEffect, useState, useRef } from "react";


// Contexts
import useDarkMode from "../../Profile/usedarkmode";
import { useCat } from "../../../utils/contexts/CategoryContext";
import { useApi } from "../../../utils/hooks/useAxiosPrivate";
import useTokenVerifier from "../../../utils/hooks/useTokenVerifier";

// Components
import Page from "../page";
import ChartComponent from "../../../components/reusable/charts/chartComponent";
import MyTable from "../../../components/reusable/table/table-component";
import CategoryFormatter from "../../../components/reusable/select/categories";
import NWeeksFormatter from "../../../components/reusable/select/nweeks";
import SelectComponent from "../../../components/reusable/select/select";
import Loading from "../../../components/reusable/loading/loading";
import NWeeksHelp from "../../../components/reusable/NWeeksHelp";

// Icons / Images
import { IoStatsChart } from "react-icons/io5";

// Define types
type ChartOptions = Record<string, any>;
type ColumnDef = { headerName: string; field: string };

const FoodCostAnalysis = () => {
  const [tokenVerified, setTokenVerified] = useState(false);

  // Callback function to update token verification status
  const handleTokenVerified = () => {
    setTokenVerified(true);
  };
  useTokenVerifier({
    onTokenVerified: handleTokenVerified,
    log: "FOODCOST ANALYSIS PAGE",
  });

  const { get } = useApi();
  const categories = useCat();
  const [nweeks, setNweeks] = useState<number>(4);
  const [category, setCategory] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const darkMode = useDarkMode();

  const [columnsD5Q21] = useState<ColumnDef[]>([
    { headerName: "Receita", field: "rest_prod_desc" },
    { headerName: "Doses", field: "doses" },
    { headerName: "Custo", field: "rec_foodcost" },
    { headerName: "%", field: "weight" },
    { headerName: "% Acumulada", field: "cumulative" },
  ]);

  const [columnsD5Q41] = useState<ColumnDef[]>([
    { headerName: "Ingrediente", field: "rest_ingr_name" },
    { headerName: "Peso", field: "kg" },
    { headerName: "Custo", field: "ingr_foodcost" },
    { headerName: "%", field: "weight" },
    { headerName: "% Acumulada", field: "cumulative" },
  ]);

  const [chartOptions, setChartOptions] = useState<
    Record<string, ChartOptions>
  >({
    q11: {},
    q12: {},
    q21: {},
    q22: {},
    q31: {},
    q32: {},
    q41: {},
  });

  const fetchChartData = async (
    endpoint: string,
    params: Record<string, any>,
    signal: AbortSignal
  ) => {
    const queryString = new URLSearchParams(params).toString();
    const url = `dash5_${endpoint}/?${queryString}`;

    try {
      const response = await get(url, { signal });
      return { endpoint, data: response.data };
    } catch (error) {
      console.error(`Error fetching ${endpoint}:`, error);
      // Toaster.show(`Erro ao carregar dados.`, "error");
      return { endpoint, data: {} };
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    setLoading(true);

    const fetchData = async () => {
      const endpoints = ["q11", "q12", "q21", "q22", "q31", "q32", "q41"];
      const promises = endpoints.map((endpoint) =>
        fetchChartData(endpoint, { nweeks, category }, controller.signal)
      );

      try {
        const results = await Promise.all(promises);
        const newChartOptions = results.reduce((acc, { endpoint, data }) => {
          acc[endpoint] = data;
          return acc;
        }, {});

        setChartOptions(newChartOptions);
        setLoading(false);
      } catch (error) {
        // Toaster.show(`Erro ao carregar dados.`, "error");
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();

    return () => controller.abort();
  }, [nweeks, category]);

  useEffect(() => {
    if (categories.Categories.length > 0) {
      setCategory(categories.Categories[0]);
    }
  }, [categories.Categories]);

  const chartRef = useRef(null);

  const handleChartFinished = () => {
    if (chartRef.current) {
      chartRef.current.getEchartsInstance().resize();
    }
  };

  return (
    <Page title={"Análise de FoodCost"} icon={<IoStatsChart size={32} />}>
      {tokenVerified ? (
        <div className="ion-padding">
          <div className="padding">
            <div className=" py-4 flex items-center">
              <div className="w-[30%]">
                <SelectComponent
                  formatter={NWeeksFormatter}
                  darkMode={darkMode}
                  onChange={setNweeks}
                />
              </div>
              <div className="w-[70%]">
                <NWeeksHelp nweeks={nweeks} />
              </div>
            </div>
            <div>
              <div className="grid xl:grid-cols-2 gap-4">
                <ChartComponent
                  chartRef={chartRef}
                  option={chartOptions.q11}
                  handleChartFinished={handleChartFinished}
                  ChangeScale={false}
                />

                <ChartComponent
                  chartRef={chartRef}
                  option={chartOptions.q12}
                  handleChartFinished={handleChartFinished}
                  ChangeScale={false}
                />

                <MyTable
                  subText="Número de doses vendidas em todo o período de análise"
                  rowData={chartOptions.q21}
                  columnDefs={columnsD5Q21}
                  title="Ranking de custos por Prato/Receita"
                  darkMode={darkMode}
                />

                <ChartComponent
                  chartRef={chartRef}
                  option={chartOptions.q22}
                  handleChartFinished={handleChartFinished}
                  ChangeScale={false}
                />
              </div>
            </div>

            {categories.Categories.length > 0 && (
              <>
                <div className="py-10">
                  <SelectComponent
                    formatter={CategoryFormatter}
                    darkMode={darkMode}
                    onChange={setCategory}
                  />
                </div>
                <div className="grid xl:grid-cols-2 gap-4">
                  <ChartComponent
                    chartRef={chartRef}
                    option={chartOptions.q31}
                    handleChartFinished={handleChartFinished}
                    ChangeScale={false}
                  />
                  <ChartComponent
                    chartRef={chartRef}
                    option={chartOptions.q32}
                    handleChartFinished={handleChartFinished}
                    ChangeScale={false}
                  />

                  <MyTable
                    rowData={chartOptions.q41}
                    columnDefs={columnsD5Q41}
                    title="Ranking de custos por Ingrediente"
                    darkMode={darkMode}
                  />
                </div>
              </>
            )}
          </div>
          <Loading isOpen={loading} />
        </div>
      ) : (
        <Loading isOpen={true} />
      )}
    </Page>
  );
};
export default FoodCostAnalysis;
