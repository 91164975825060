// React
import { useState } from "react";

// Hooks
import useTokenVerifier from "../../../utils/hooks/useTokenVerifier";

// Components
import Page from "../page";
import Loading from "../../../components/reusable/loading/loading";
import ProfileSetup from "./initialSetup/ProfileSetup";
import RestaurantSetup from "./initialSetup/RestaurantSetup";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../../components/ui/accordion";
import SaftSetup from "./initialSetup/SaftSetup";
import {
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card";
import CategoriesSetup from "./initialSetup/CategoriesSetup";

const RestSettings = () => {
  const [tokenVerified, setTokenVerified] = useState(false);

  // Callback function to update token verification status
  const handleTokenVerified = () => {
    setTokenVerified(true);
  };
  useTokenVerifier({
    onTokenVerified: handleTokenVerified,
    log: "PROFILE RESTAURANT PAGE",
  });
  return (
    <Page>
      <CardHeader>
        <CardTitle>Configurações do Restaurante</CardTitle>
        <CardDescription>
          Configure as informações do seu restaurante aqui.
        </CardDescription>
      </CardHeader>
      <Accordion type="single" collapsible className="w-full mt-5">
        <AccordionItem value="item-1">
          <AccordionTrigger>
            {tokenVerified ? "Restaurante" : "Loading..."}
          </AccordionTrigger>
          <AccordionContent>
            {tokenVerified ? <RestaurantSetup /> : <Loading isOpen={true} />}
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value="item-2">
          <AccordionTrigger>
            {tokenVerified ? "Perfil" : "Loading..."}
          </AccordionTrigger>
          <AccordionContent>
            {tokenVerified ? <ProfileSetup /> : <Loading isOpen={true} />}
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value="item-3">
          <AccordionTrigger>
            {tokenVerified ? "Categorias de Produtos" : "Loading..."}
          </AccordionTrigger>
          <AccordionContent>
            {tokenVerified ? <CategoriesSetup /> : <Loading isOpen={true} />}
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value="item-4">
          <AccordionTrigger>
            {tokenVerified ? "Regras de Vendas" : "Loading..."}
          </AccordionTrigger>
          <AccordionContent>
            {tokenVerified ? <SaftSetup /> : <Loading isOpen={true} />}
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </Page>
  );
};

export default RestSettings;
