// React
import React, { useState, useEffect, useRef } from "react";

// contexts
import useDarkMode from "../../Profile/usedarkmode";
import { useApi } from "../../../utils/hooks/useAxiosPrivate";
import useTokenVerifier from "../../../utils/hooks/useTokenVerifier";

// components
import Page from "../page";
import ChartComponent from "../../../components/reusable/charts/chartComponent";
import MyTable from "../../../components/reusable/table/table-component";
import SelectComponent from "../../../components/reusable/select/select";
import NWeeksFormatter from "../../../components/reusable/select/nweeks";
import KpiFormatter from "../../../components/reusable/select/kpi";
import Loading from "../../../components/reusable/loading/loading";
import CardComponent from "../../../components/reusable/cardComponent";
import {
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card";

type ChartOptions = Record<string, any>;

const SalesPage = () => {
  const [tokenVerified, setTokenVerified] = useState(false);

  // Callback function to update token verification status
  const handleTokenVerified = () => {
    setTokenVerified(true);
  };
  useTokenVerifier({
    onTokenVerified: handleTokenVerified,
    log: "SALES PAGE - SHORTTERM",
  });

  const { get } = useApi();
  const [loading, setLoading] = useState(true);
  const [chartLoaded, setChartLoaded] = useState(false);
  const [nweeks, setNweeks] = useState<number>(4);
  const [kpi, setKpi] = useState("quantity");
  const chartRef = useRef(null);
  const [graphData, setGraphData] = useState<Record<string, ChartOptions>>({
    q11: {},
    q12: {},
    q21: {},
    q22: {},
    q31: {},
    q32: {},
    q41: {},
    q42: {},
    q52: {},
  });

  const [kpis, setKpis] = useState({
    absolute: [0, 0, 0, 0],
    variation: [0, 0, 0, 0],
  });
  const [lastDate, setLastDate] = useState(null);
  const [columns, setColumns] = useState([
    { headerName: "Descrição", field: "rest_prod_desc" },
    { headerName: "Unidades", field: "units" },
    { headerName: "Total de vendas", field: "total_sales" },
    { headerName: "%", field: "pct_sales" },
    { headerName: "% Acumulada", field: "pct_cumulative" },
  ]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const darkMode = useDarkMode();

  function handleChartFinished() {
    if (chartRef.current) {
      chartRef.current.getEchartsInstance().resize();
    }
    setChartLoaded(true);
  }

  useEffect(() => {
    const controller = new AbortController();

    const fetchGraphData = async () => {
      try {
        const promises = [
          get(`dash4_cp_q01/?nweeks=${nweeks}`),
          get(`dash4_cp_q02/?nweeks=${nweeks}`),
          get(`dash4_cp_q11/?nweeks=${nweeks}&kpi=${kpi}`),
          get("dash4_sales_last_date"),
          get(`dash4_cp_q21/?nweeks=${nweeks}&kpi=${kpi}`),
          get(`dash4_cp_q22/?nweeks=${nweeks}&kpi=${kpi}`),
          get(`dash4_cp_q31/?nweeks=${nweeks}`),
          get(`dash4_cp_q32/?nweeks=${nweeks}`),
          get(`dash4_cp_q41/?nweeks=${nweeks}&kpi=${kpi}`),
          get(`dash4_cp_q42/?nweeks=${nweeks}&kpi=${kpi}`),
          get(`dash4_cp_q52/?nweeks=${nweeks}`),
        ];

        const responses = await Promise.all(promises);
        const [
          q01Data,
          q02Data,
          q11Data,
          lastDateData,
          q21Data,
          q22Data,
          q31Data,
          q32Data,
          q41Data,
          q42Data,
          q52Data,
        ] = responses;

        setGraphData({
          q01: q01Data.data,
          q02: q02Data.data,
          q11: q11Data.data,
          q21: q21Data.data,
          q22: q22Data.data,
          q31: q31Data.data,
          q32: q32Data.data,
          q41: q41Data.data,
          q42: q42Data.data,
          q52: q52Data.data,
        });
        setLastDate(lastDateData.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    const fetchKpiData = async () => {
      try {
        const response = await get(`get_sales_kpi/?nweeks=${nweeks}`);
        setKpis(response.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    if (nweeks) {
      fetchGraphData();
      fetchKpiData();
    }

    return () => {
      controller.abort();
    };
  }, [nweeks, kpi]);

  const kpiDescription = {
    quantity:
      "Items vendidos representa o número de produtos individuais vendidos pelo restaurante",
    total_sales:
      "Total de vendas representa o valor total de vendas gerado pelo restaurante",
    numero_faturas:
      "Número de faturas representa o número total de faturas emitidas pelo restaurante",
  };

  useEffect(() => {
    if (graphData.q52 && searchQuery) {
      const filtered = graphData.q52?.filter((recipe) =>
        recipe.rest_prod_desc.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredData(filtered);
    }
  }, [graphData.q52, searchQuery]);
  return (
    <Page>
      {tokenVerified ? (
        <div>
          <div className="flex flex-inline justify-between">
            <CardHeader>
              <CardTitle>Análise de Vendas - Curto Prazo</CardTitle>
              <CardDescription>
                Veja a evolução das vendas do seu restaurante
              </CardDescription>
            </CardHeader>
            <div className="mt-5 mil:pt-0">
              <SelectComponent
                formatter={NWeeksFormatter}
                darkMode={darkMode}
                onChange={setNweeks}
              />
            </div>
          </div>

          {kpis && (
            <div className="mt-5">
              <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-4">
                <CardComponent
                  title={"Total de Vendas"}
                  value={`${kpis["absolute"][1]}€`}
                  variation={kpis["variation"][1]}
                />
                <CardComponent
                  title={"Nº Total de Faturas"}
                  value={`${kpis["absolute"][0]}`}
                  variation={kpis["variation"][0]}
                />
                <CardComponent
                  title={"Valor médio p/Fatura"}
                  value={`${kpis["absolute"][3]}€`}
                  variation={kpis["variation"][3]}
                />
                <CardComponent
                  title={"Itens vendidos"}
                  value={`${kpis["absolute"][2]}`}
                  variation={kpis["variation"][2]}
                />
              </div>
            </div>
          )}

          <div className="padding">
            <div className="grid xl:grid-cols-2 gap-4">
              <ChartComponent
                chartRef={chartRef}
                option={graphData.q01}
                handleChartFinished={handleChartFinished}
                ChangeScale={false}
              />

              <ChartComponent
                chartRef={chartRef}
                option={graphData.q02}
                handleChartFinished={handleChartFinished}
                ChangeScale={false}
              />

              <ChartComponent
                chartRef={chartRef}
                option={graphData.q31}
                handleChartFinished={handleChartFinished}
                ChangeScale={false}
              />

              <ChartComponent
                chartRef={chartRef}
                option={graphData.q32}
                handleChartFinished={handleChartFinished}
                ChangeScale={false}
              />
            </div>

            <div className="flex flex-inline justify-between">
              <CardHeader className="mt-10 mb-5">
                <CardTitle>Análise por categoria</CardTitle>
                <CardDescription>
                  Analise as vendas do seu restaurante por categoria.{" "}
                  {kpiDescription[kpi]}
                </CardDescription>
              </CardHeader>
              <div className="pt-4 mil:pt-0">
                <SelectComponent
                  formatter={KpiFormatter}
                  darkMode={darkMode}
                  onChange={setKpi}
                />
              </div>
            </div>

            <div className="grid xl:grid-cols-2 gap-4">
              <ChartComponent
                chartRef={chartRef}
                option={graphData.q21}
                handleChartFinished={handleChartFinished}
                ChangeScale={false}
              />

              <ChartComponent
                chartRef={chartRef}
                option={graphData.q22}
                handleChartFinished={handleChartFinished}
                ChangeScale={false}
              />
            </div>

            <CardHeader className="mt-10 mb-5">
              <CardTitle>Análise diária</CardTitle>
              <CardDescription>
                Entenda a evolução das vendas do seu restaurante por dia da
                semana
              </CardDescription>
            </CardHeader>

            <div className="grid xl:grid-cols-2 gap-4">
              <ChartComponent
                chartRef={chartRef}
                option={graphData.q41}
                handleChartFinished={handleChartFinished}
                ChangeScale={false}
              />

              <ChartComponent
                chartRef={chartRef}
                option={graphData.q42}
                handleChartFinished={handleChartFinished}
                ChangeScale={false}
              />
            </div>
            <div className="py-10 w-full">
              {/* <IonSearchbar
                value={searchQuery}
                onIonChange={(e) => setSearchQuery(e.detail.value)}
                placeholder="Pesquisar por receita"
                style={{
                  "--ion-background-color": darkMode ? "#262853" : "white",
                }}
              /> */}
              <div className="w-full">
                <MyTable
                  rowData={
                    filteredData.length > 0 ? filteredData : graphData.q52
                  }
                  columnDefs={columns}
                  darkMode={darkMode}
                  title="Ranking de receitas geradas por cada produto à venda"
                />
              </div>
            </div>
          </div>

          <Loading isOpen={loading} />
        </div>
      ) : (
        <Loading isOpen={true} />
      )}
    </Page>
  );
};
export default SalesPage;
