// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scroll {
  --var1: transparent; /* Initial color for the scrollbar thumb */
  --var2: transparent; /* Initial color for the scrollbar background */
  transition: all 0.5s ease-in-out; /* Apply transition to all properties */
}

.scroll:hover {
  --var1: #484c9c;
  --var2: #ffffff;
}

.scroll::-webkit-scrollbar {
  background: var(--var2);
  
  width: 8px;
  height: 8px;
}

.scroll::-webkit-scrollbar-thumb {
  background: var(--var1);
  border-radius: 8px;
  -webkit-transition: background 0.5s;
  transition: background 0.5s; /* Apply transition to the background color */
}`, "",{"version":3,"sources":["webpack://./src/assets/css/customScroll.styles.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB,EAAE,0CAA0C;EAC/D,mBAAmB,EAAE,+CAA+C;EACpE,gCAAgC,EAAE,uCAAuC;AAC3E;;AAEA;EACE,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,uBAAuB;;EAEvB,UAAU;EACV,WAAW;AACb;;AAEA;EACE,uBAAuB;EACvB,kBAAkB;EAClB,mCAA2B;EAA3B,2BAA2B,EAAE,6CAA6C;AAC5E","sourcesContent":[".scroll {\n  --var1: transparent; /* Initial color for the scrollbar thumb */\n  --var2: transparent; /* Initial color for the scrollbar background */\n  transition: all 0.5s ease-in-out; /* Apply transition to all properties */\n}\n\n.scroll:hover {\n  --var1: #484c9c;\n  --var2: #ffffff;\n}\n\n.scroll::-webkit-scrollbar {\n  background: var(--var2);\n  \n  width: 8px;\n  height: 8px;\n}\n\n.scroll::-webkit-scrollbar-thumb {\n  background: var(--var1);\n  border-radius: 8px;\n  transition: background 0.5s; /* Apply transition to the background color */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
