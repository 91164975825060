// React
import { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Hooks
import useTokenVerifier from "../../../../utils/hooks/useTokenVerifier";
import useDarkMode from "../../../Profile/usedarkmode";
import useAxiosPrivate from "../../../../utils/hooks/useAxiosPrivate";

// Components
import Page from "../../page";
import MyTable from "../../../../components/reusable/table/table-component";
import CategoryFormatter from "../../../../components/reusable/select/categories";
import ChartComponent from "../../../../components/reusable/charts/chartComponent";
import SelectComponent from "../../../../components/reusable/select/select";
import RangeFormatter from "../../../../components/reusable/select/range";
import ProviderFormatter from "../../../../components/reusable/select/provider";
import Loading from "../../../../components/reusable/loading/loading";
import Paginate from "../../../../components/reusable/pagination/paginate";
import { Button } from "../../../../components/ui/button";
import {DatePicker} from "../../../../components/ui/datePicker";

// Icons
import { MdSearch } from "react-icons/md";
import { FaArrowCircleLeft } from "react-icons/fa";
import Modal from "../../../../components/reusable/Modal";
import Toaster from "../../../../components/reusable/Toaster";

const IndividualQuery = () => {
  // TOKEN VERIFY AND LOGS
  const [tokenVerified, setTokenVerified] = useState(false);

  const handleTokenVerified = () => {
    setTokenVerified(true);
  };
  useTokenVerifier({
    onTokenVerified: handleTokenVerified,
    log: "QUERY PAGE",
  });

  // PAGE
  const axiosPrivate = useAxiosPrivate();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const nConsulta = parseInt(queryParams.get("id"), 10);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [consulta, setConsulta] = useState([]);
  const [category, setCategory] = useState("Bebidas");
  const [priceRange, setPriceRange] = useState("<= 1€");
  const [prov, setProv] = useState("Pingo Doce");
  let showCategory = true;
  const [showModal, setShowModal] = useState(false);
  const [product, setProduct] = useState([]);
  const [productToFetch, setProductToFetch] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [change, setChange] = useState(false);
  const [message, setMessage] = useState("");

  function formatDate(dateString, hour = true) {
    const date = new Date(dateString);
    if (!hour) {
      const formattedDate = date.toLocaleDateString("pt-PT");
      return formattedDate;
    }
    const formattedDate = date.toLocaleDateString("pt-PT", {
      hour: "numeric",
      minute: "numeric",
    });
    return formattedDate;
  }

  const today = new Date();
  const nowDate = today.toISOString();
  today.setHours(23, 59, 59, 0);
  const formattedToday = today.toISOString();
  // const [showModalDate, setShowModalDate] = useState(false);
  const [date, setDate] = useState<Date>(today);

  const columns = {
    5: [
      { headerName: "Produto", field: "sku_prod_name" },
      { headerName: "Fornecedor", field: "sku_prov_name" },
      { headerName: "Categoria", field: "sku_category" },
      { headerName: "Preço(€)", field: "last_sku_price" },
      { headerName: "Data do Preço", field: "last_date_sku_price" },
      { headerName: "Consultar", field: "consultarItem" },
    ],
    6: [
      { headerName: "Produto", field: "sku_prod_name" },
      { headerName: "Fornecedor", field: "sku_prov_name" },
      { headerName: "Categoria", field: "sku_category" },
      { headerName: "Preço(€)", field: "last_sku_price" },
      { headerName: "Data do Preço", field: "last_date_sku_price" },
      { headerName: "Consultar", field: "consultarItem" },
    ],
    3: [
      { headerName: "Produto", field: "recipe_name" },
      { headerName: "Doses servidas", field: "recipe_order" },
      { headerName: "Preço(€)", field: "last_foodcost" },
      { headerName: "Data do Preço", field: "last_date_foodcost" },
    ],
    2: [
      { headerName: "Nome da Receita", field: "recipe_name" },
      { headerName: "Descrição", field: "recipe_descrip" },
      { headerName: "Doses servidas", field: "recipe_order" },
      { headerName: "Utilização", field: "recipe_usage" },
    ],
    4: [
      { headerName: "Nome do Ingrediente", field: "ingr_name" },
      { headerName: "Processamento", field: "ingr_degree_process" },
      { headerName: "Preço(€)", field: "last_foodcost" },
      { headerName: "Data do Preço", field: "last_date_foodcost" },
      { headerName: "Consultar", field: "consultarItem" },
    ],
    1: [
      { headerName: "Nome", field: "username" },
      { headerName: "Cargo", field: "user_comments" },
      { headerName: "Email", field: "email" },
      { headerName: "Estado", field: "user_status" },
    ],
    7: [
      { headerName: "Nome do Script", field: "scriptname" },
      { headerName: "Tipo de Log", field: "log_type" },
      { headerName: "Estado da operação", field: "log_oper_status" },
      { headerName: "Utilizador", field: "username" },
      { headerName: "Data", field: "zdata" },
      { headerName: "Descrição da operação", field: "log_oper_descr" },
      { headerName: "Observações", field: "log_obs" },
    ],
    8: [
      { headerName: "Utilizador", field: "username" },
      { headerName: "Tipo de log", field: "log_type" },
      { headerName: "Estado da operação", field: "log_oper_status" },
      { headerName: "Data", field: "zdata" },
      { headerName: "Descrição da operação", field: "log_oper_descr" },
      { headerName: "Observações", field: "log_obs" },
    ],
    9: [
      { headerName: "Data", field: "date_field" },
      { headerName: "Pingo Doce", field: "1" },
      { headerName: "Continente", field: "2" },
      { headerName: "Mini Preço", field: "3" },
      { headerName: "Makro", field: "4" },
    ],
    10: [
      { headerName: "Data", field: "date_field" },
      { headerName: "Fornecedor", field: "sku_provider_id" },
      { headerName: "Preços scraper", field: "raw" },
      { headerName: "Preços limpos", field: "clean" },
      { headerName: "Preços finais", field: "fact" },
      { headerName: "Variação entre scraper e limpos", field: "delta1" },
      { headerName: "Variação entre limpos e finais", field: "delta2" },
    ],
    11: [
      { headerName: "Categoria do SKU", field: "sku_category" },
      { headerName: "Número de SKUs", field: "count" },
    ],
    12: [
      { headerName: "Data", field: "sku_dt_ref" },
      { headerName: "Categoria do SKU", field: "sku_category" },
      { headerName: "Número de SKUs novos", field: "count" },
    ],

    13: [
      { headerName: "Id", field: "ingr_id" },
      { headerName: "Nome", field: "ingr_name" },
      { headerName: "Nome técnico", field: "ingr_name_tecn" },
      { headerName: "Descrição", field: "ingr_descrip" },
      { headerName: "Preço Média à Data", field: "ingr_price_avg" },
      //????
    ],
    14: [
      { headerName: "Ano/Semana", field: "dt_anosemana" },
      { headerName: "Id do Ingrediente", field: "ingr_id" },
      { headerName: "Nome do Ingrediente", field: "ingr_name" },
      { headerName: "Média de preço", field: "price_avg" },
      { headerName: "Id do restaurante", field: "rest_site_id" },
    ],
    15: [
      { headerName: "Data", field: "price_date" },
      { headerName: "Id da Receita", field: "recipe_id" },
      { headerName: "Nome da Receita", field: "recipe_name" },
      { headerName: "Preço por kg", field: "recipe_price_kg" },
      { headerName: "Id do restaurante", field: "rest_site_id" },
    ],
    16: [
      { headerName: "Ano/Semana", field: "dt_anosemana" },
      { headerName: "Id da Receita", field: "recipe_id" },
      { headerName: "Nome da Receita", field: "recipe_name" },
      { headerName: "Média de preço", field: "recipe_price_avg" },
    ],
    17: [
      { headerName: "Data de referência", field: "dt_ref" },
      { headerName: "Id do fornecedor", field: "sku_provider_id" },
      { headerName: "Número total de registos", field: "total_registos" },
      { headerName: "Observações", field: "sku_price_obs" },
    ],
    18: [
      { headerName: "Data de referência", field: "dt_ref" },
      { headerName: "Id do Ingrediente", field: "ingr_id" },
      { headerName: "Nome do Ingrediente", field: "ingr_name" },
      { headerName: "Número total de registos", field: "total_registos" },
    ],
    19: [
      { headerName: "Data de referência", field: "dt_ref" },
      { headerName: "Id da Receita", field: "recipe_id" },
      { headerName: "Nome da Receita", field: "nome" },
      { headerName: "Número total de registos", field: "total_registos" },
    ],
    20: [
      { headerName: "Ano/Semana", field: "dt_anosemana" },
      {
        headerName: "Número total de registos semanais de SKUs",
        field: "total_reg_sku_wk",
      },
      {
        headerName: "Número total de registos semanais de deltas para os SKUs",
        field: "total_reg_sku_wk_mdelta",
      },
      {
        headerName: "Número total de registos semanais de categorias SKUs",
        field: "total_reg_ctg_sku_wk",
      },
      {
        headerName:
          "Número total de registos semanais de deltas para as categorias SKUs",
        field: "total_reg_ctg_sku_wk_mdelta",
      },
    ],
    21: [
      { headerName: "Ano/Semana", field: "dt_anosemana" },
      {
        headerName: "Número total de registos semanais de ingredientes",
        field: "total_reg_ingr_wk",
      },
      {
        headerName: "Número total de registos multi-semanais de ingredientes",
        field: "total_reg_ingr_wk_mdelta",
      },
      {
        headerName: "Número total de registos semanais de receitas",
        field: "total_reg_recipe_wk",
      },
      {
        headerName:
          "Número total de registos semanais de deltas para as receitas",
        field: "total_reg_recipe_wk_delta",
      },
    ],
  };

  const titles = {
    1: "Consulta dos dados próprios do Cliente",
    2: "Consulta de Receitas",
    3: "Consulta últimos valores de custo de Receitas",
    4: "Consulta últimos valores de custo de Ingredientes",
    5: "Consulta últimos valores de custo de SKUs Ativos",
    6: "Consulta últimos valores de custo de SKUs Não Ativos",
    7: "Consulta de logs dos Scripts",
    8: "Consulta de logs dos Utilizadores",
    9: "Consulta da Recolha Diária de dados recolhidos pelos scrapers por fornecedores",
    10: "Consulta do processo de Recolha Diária de Preços SKU por fornecedores",
    11: "Consulta de todos os SKUs e SKUs classificados via GPT",
    12: "Consulta de SKUs novos e SKUs classificados via GPT",
    13: "Consulta de Preço de Ingredientes - Valores Diários",
    14: "Consulta de Preço de Ingredientes - Valores Semanais",
    15: "Consulta de Preços de Receitas diários",
    16: "Consulta de Preços de Receitas - Valores Semanais",
    17: "Consulta de Preços de SKUs",
    18: "Consulta de Preços de Ingredientes",
    19: "Consulta de Preços de Receitas",
    20: "Consulta da Disponibilidade de Estatísticas Semanais SKUs",
    21: "Consulta da Disponibilidade de Estatísticas Semanais Ingredientes e Receitas",
  };

  const searchForMessages = {
    1: "Pesquisar por nome do funcionário",
    2: "Pesquisar por nome da receita",
    3: "Pesquisar por nome da receita",
    4: "Pesquisar por nome do ingrediente",
    5: "Pesquisar por nome do produto",
    6: "Pesquisar por nome do produto",
    7: "Pesquisar por nome do script",
    8: "Pesquisar por nome do utilizador",
    9: "Pesquisar por nome do utilizador",
    10: "Pesquisar por nome do utilizador",
    11: "Pesquisar por nome do utilizador",
    12: "Pesquisar por nome do utilizador",
    13: "Pesquisar por nome do utilizador",
    14: "Pesquisar por nome do utilizador",
    15: "Pesquisar por nome do utilizador",
    16: "Pesquisar por nome do utilizador",
    17: "Pesquisar por data de referência",
    18: "Pesquisar por data de referência",
    19: "Pesquisar por data de referência",
    20: "Pesquisar por data de referência",
    21: "Pesquisar por data de referência",
  };

  const responseType = {
    1: "funcionários",
    2: "receitas",
    3: "receitas",
    4: "ingredientes",
    5: "produtos",
    6: "produtos",
    7: "logs",
    8: "logs",
    9: "mudar",
    10: "mudar",
    11: "mudar",
    12: "mudar",
    13: "mudar",
    14: "mudar",
    15: "mudar",
    16: "mudar",
    17: "mudar",
    18: "mudar",
    19: "mudar",
    20: "mudar",
    21: "mudar",
  };

  const chosenColumns = columns[nConsulta];
  const searchFor = searchForMessages[nConsulta];
  const response = responseType[nConsulta];
  const title = titles[nConsulta];

  const datetime = useRef<null | any>(null);
  if (nConsulta !== 5 && nConsulta !== 6) {
    showCategory = false;
  }

  useEffect(() => {
    const controller = new AbortController();
    if (nConsulta != null) {
      setLoading(true);
      const getGraph = async (endpoint, params = {}) => {
        const url = params
          ? `c1_q${endpoint}/?${Object.entries(params)
              .map(([key, value]) => `${key}=${value}`)
              .join("&")}`
          : `c1_q${endpoint}`;

        try {
          // Using functional form of setState to ensure latest state values

          const response = await axiosPrivate.get(url, {
            signal: controller.signal,
          });
          const data = response.data.data;

          setConsulta(data);
          setTotalPages(response.data.total_pages);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.error(error);
        }
      };

      const convertedDate = convertDateToYYYYMMDD(date);
      !showCategory
        ? getGraph(nConsulta, {
            page: currentPage,
            search:
              nConsulta === 13 || (nConsulta >= 17 && nConsulta <= 21)
                ? convertedDate
                : searchQuery,
          })
        : getGraph(nConsulta, {
            page: currentPage,
            category: category,
            price_range: RangeFormatter.rangeToCategory(priceRange),
            prov_name: prov,
          });
    }

    return () => {
      controller.abort();
    };
  }, [
    axiosPrivate,
    category,
    currentPage,
    nConsulta,
    priceRange,
    prov,
    searchQuery,
    change,
    date,
  ]);

  const darkMode = useDarkMode();

  useEffect(() => {
    const controller = new AbortController();

    const getGraph = async (endpoint, params = {}) => {
      setMessage("");
      const url = params
        ? `c1_grf1${endpoint}/?${Object.entries(params)
            .map(([key, value]) => `${key}=${value}`)
            .join("&")}`
        : `c1_grf1${endpoint}`;
      setLoading(true);
      try {
        const response = await axiosPrivate.get(url, {
          signal: controller.signal,
        });

        if (response.data.message) {
          setMessage(response.data.message);
          setLoading(false);
          return;
        }

        setProduct(response.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    const getGraph_Query4 = async (endpoint, params = {}) => {
      const url = params
        ? `c4_grf${endpoint}/?${Object.entries(params)
            .map(([key, value]) => `${key}=${value}`)
            .join("&")}`
        : `c4_grf${endpoint}`;
      setLoading(true);
      try {
        const response = await axiosPrivate.get(url, {
          signal: controller.signal,
        });

        setProduct(response.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    if (nConsulta === 5 || nConsulta === 6) {
      setProduct([]);
      getGraph("", { sku_id: productToFetch });
      (productToFetch && message === "") ? setShowModal(true) : setShowModal(false);
      if(message !== "") {
        Toaster.show(message, "error");
      }
    }

    if (nConsulta === 4) {
      setProduct([]);
      getGraph_Query4("", { ingre_id: productToFetch });
      (productToFetch) ? setShowModal(true) : setShowModal(false);
    }
    return () => {
      controller.abort();
    };
  }, [productToFetch]);

  const chartRef = useRef(null);
  const [chartLoaded, setChartLoaded] = useState(false);

  function handleChartFinished() {
    if (chartRef.current) {
      chartRef.current.getEchartsInstance().resize();
    }
    setChartLoaded(true);
  }

  const handleKeyUp = (e) => {
    if (e.key === "Enter") {
      setLoading(true);
      if (e.target.value === "") {
        setLoading(false);
        return;
      }
      setSearchQuery(e.target.value);
      setChange(!change);
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  function convertDateToYYYYMMDD(date: Date): string {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}${month}${day}`;
  }

  return (
    <Page title={"Consulta"} icon={<MdSearch size={32} />}>
      {tokenVerified ? (
        <div>
          <FaArrowCircleLeft size={32} onClick={goBack} />
          <div>
            <div>
              <div className="homePageText">
                <div
                  className="welcome"
                  style={{
                    color: darkMode ? "#f4f4f4" : "black",
                    paddingLeft: "3rem",
                  }}
                >
                  {title}
                </div>
              </div>
              {(nConsulta === 13 || (nConsulta >= 17 && nConsulta <= 21)) && (
                <div className="py-8">
                    <DatePicker
                      date={date}
                      setDate={setDate}
                      maxDate={today}
                    />
                </div>
              )}
              {showCategory && (
                <>
                  <div className="flex flex-col mil:flex-row justify-evenly py-10 gap-4">
                    <div style={{ justifyContent: "center", display: "flex" }}>
                      <SelectComponent
                        formatter={CategoryFormatter}
                        darkMode={darkMode}
                        onChange={setCategory}
                      />
                    </div>
                    <div style={{ justifyContent: "center", display: "flex" }}>
                      <SelectComponent
                        formatter={RangeFormatter}
                        darkMode={darkMode}
                        onChange={setPriceRange}
                      />
                    </div>
                    <div style={{ justifyContent: "center", display: "flex" }}>
                      <SelectComponent
                        formatter={ProviderFormatter}
                        darkMode={darkMode}
                        onChange={setProv}
                      />
                    </div>
                  </div>
                  <Modal isOpen={showModal} setIsOpen={setShowModal}>
                    <div
                      style={{ overflow: "auto" }}
                      className="scrollbar h-full"
                    >
                      <ChartComponent
                        chartRef={chartRef}
                        option={product}
                        handleChartFinished={handleChartFinished}
                      />
                    </div>
                  </Modal>
                </>
              )}
              {nConsulta === 4 && (
                <Modal isOpen={showModal && !loading} setIsOpen={setShowModal}>
                    <div className="py-4 flex justify-center w-full">
                      <p className="text-xl">
                        Evolução de preços referente aos últimos 30 dias
                      </p>
                    </div>
                    <div className="grid xl:grid-cols-2">
                        <ChartComponent
                          chartRef={chartRef}
                          option={product[0]}
                          handleChartFinished={handleChartFinished}
                          ChangeScale={false}
                        />

                        <ChartComponent
                          chartRef={chartRef}
                          option={product[1]}
                          handleChartFinished={handleChartFinished}
                          ChangeScale={false}
                        />
                    </div>
                </Modal>
              )}
            </div>
            <div className="padding">
              <>
                {nConsulta !== 13 &&
                  nConsulta !== 5 &&
                  nConsulta !== 6 &&
                  nConsulta >= 17 && (
                    <div className="search top">
                      {/* <IonSearchbar
                        animated={true}
                        value={searchQuery}
                        onKeyDown={handleKeyUp}
                        placeholder={searchFor}
                        style={{
                          "--ion-background-color": darkMode
                            ? "#262853"
                            : "white",
                        }}
                        onIonClear={() => {
                          setSearchQuery("");
                          setChange(!change);
                        }}
                      /> */}
                    </div>
                  )}
                {consulta?.length !== 0 ? (
                  <MyTable
                    rowData={consulta}
                    columnDefs={chosenColumns}
                    darkMode={darkMode}
                    title={title}
                    setProductToFetch={setProductToFetch}
                    consulta={nConsulta}
                    //admin={auth.roles.includes("backoffice")}
                    changedList={nConsulta === 5 ? change : false}
                    setChangedList={nConsulta === 5 ? setChange : false}
                  />
                ) : (
                  !loading && (
                    <div
                      style={{ color: darkMode ? "white" : "black" }}
                      className="text-center text-2xl mt-10"
                    >
                      {searchQuery !== "" ? (
                        <p>
                          Sem {response} que contenham {searchQuery} no nome
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  )
                )}
                {consulta?.length === 20 && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      paddingTop: "20px",
                    }}
                  >
                    <Paginate
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      totalPages={totalPages}
                      loading={!consulta}
                    />
                  </div>
                )}
              </>
            </div>
          </div>
          <Loading isOpen={loading} />
        </div>
      ) : (
        <Loading isOpen={true} />
      )}
    </Page>
  );
};
export default IndividualQuery;
