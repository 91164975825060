// React
import { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";


// contexts
import useDarkMode from "../../Profile/usedarkmode";

// components
import Page from "../page";
import ChartComponent from "../../../components/reusable/charts/chartComponent";
import ChartFunc from "../../../components/reusable/charts/chartFunc";
import SelectComponent from "../../../components/reusable/select/select";
import LabelFormatter from "../../../components/reusable/select/label";
import ProdFormatter from "../../../components/reusable/select/num_prod";
import Loading from "../../../components/reusable/loading/loading";

// icons / images
import { MdPeople } from "react-icons/md";

// hooks
import useTokenVerifier from "../../../utils/hooks/useTokenVerifier";
import useAxiosPrivate from "../../../utils/hooks/useAxiosPrivate";

const CustomerView = () => {
  const [tokenVerified, setTokenVerified] = useState(false);

  // Callback function to update token verification status
  const handleTokenVerified = () => {
    setTokenVerified(true);
  };
  useTokenVerifier({
    onTokenVerified: handleTokenVerified,
    log: "CUSTOMER VIEW PAGE",
  });

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const customerN = queryParams.get("id");
  const [label, setLabel] = useState("Melhor");
  const [analysis, setAnalysis] = useState({});
  const [num_prod, setNumProd] = useState(3);
  const axiosPrivate = useAxiosPrivate();
  const [loading, setLoading] = useState(true);
  const [customerCharts, setCustomerCharts] = useState({
    chart4: { option: {}, func: "", drilldown_data: "" },
    chart5: { option: {}, func: "", drilldown_data: "" },
    rfmClasses: ["loading..."],
  });
  const chartRef = useRef(null);

  useEffect(() => {
    const controller = new AbortController();
    setLoading(true);

    // Get analysis details
    const getAnalysisDetail = async () => {
      try {
        const response = await axiosPrivate.get(
          `analysis_detail/${customerN}`,
          {
            signal: controller.signal,
          }
        );
        setAnalysis(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    // Get analysis charts
    const getCharts = async (endpoint, params = {}) => {
      console.log(
        "getCharts called with endpoint:",
        endpoint,
        "and params:",
        params
      );
      const url = params
        ? `${endpoint}/?${Object.entries(params)
          .map(([key, value]) => `${key}=${value}`)
          .join("&")}`
        : `${endpoint}`;

      try {
        const response = await axiosPrivate.get(url, {
          signal: controller.signal,
        });
        console.log("getCharts response:", response.data);
        setCustomerCharts(response.data);
        if (response.data["rfmClasses"] && customerCharts.rfmClasses.includes("loading..."))
          setLabel(response.data["rfmClasses"][0]);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    // Run
    Promise.all([
      getAnalysisDetail(),
      getCharts("customer_analysis_charts", {
        an_id: customerN,
        label: label,
        num_prod: num_prod,
      }),
    ]);

    return () => {
      controller.abort();
    };
  }, [axiosPrivate, customerN, label, num_prod]);

  const darkMode = useDarkMode();

  return (
    <Page

    >
      {tokenVerified ? (
        <div>
          <div
            style={{ paddingBottom: "20px", paddingTop: "10px" }}
            className="padding"
          >
            <div className="bg-white overflow-hidden rounded-lg">
              <div className="md:flex">
                <div className="md:w-1/2 p-8">
                  <div className="uppercase tracking-wide text-sm text-gray-700 font-semibold">
                    {analysis["an_nome"]}
                  </div>
                  <p className="bg-gray-100 mt-2 text-gray-600 p-4 rounded-lg">
                    {analysis["an_descr"]}
                  </p>
                </div>
                <div className="md:w-1/2 p-8 text-sm">
                  <div className="grid grid-cols-2 mb-3">
                    <p className="text-gray-500 font-bold">Data de início</p>
                    <p className="text-gray-500 text-right">
                      {analysis["an_dt_ini"]}
                    </p>
                  </div>
                  <div className="grid grid-cols-2 mb-3">
                    <p className="text-gray-500 font-bold">Data de fim</p>
                    <p className="text-gray-500 text-right">
                      {analysis["an_dt_fim"]}
                    </p>
                  </div>
                  <div className="grid grid-cols-2 mb-3">
                    <p className="text-gray-500 font-bold">Status</p>
                    <p className="text-gray-500 text-right">
                      {analysis["an_obs"]}
                    </p>
                  </div>
                  <div className="grid grid-cols-2">
                    <p className="text-gray-500 font-bold">Criado em</p>
                    <p className="text-gray-500 text-right">
                      {analysis["zdata"]}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid xl:grid-cols-2 gap-4 padding py-6">
              <ChartComponent
                positionDescription="right"
                chartRef={chartRef}
                option={customerCharts["chart1"]}
                ChangeScale={false}
              />
              <ChartComponent
                positionDescription="left"
                chartRef={chartRef}
                option={customerCharts["chart2"]}
                ChangeScale={false}
              />
            </div>
            <div className="flex gap-8 py-6">
              <SelectComponent
                formatter={new LabelFormatter(customerCharts["rfmClasses"])}
                darkMode={darkMode}
                onChange={setLabel}
              />
              <SelectComponent
                formatter={ProdFormatter}
                darkMode={darkMode}
                onChange={setNumProd}
              />
            </div>
            <div className="grid xl:grid-cols-2 gap-4 padding py-6">
              <ChartComponent
                positionDescription="right"
                chartRef={chartRef}
                option={customerCharts["chart3"]}
                ChangeScale={false}
              />
              <ChartFunc
                chartRef={chartRef}
                option={customerCharts["chart4"]["option"]}
                func={customerCharts["chart4"]["func"]}
                drilldown_data={customerCharts["chart4"]["drilldown_data"]}
              />
              <ChartFunc
                chartRef={chartRef}
                option={customerCharts["chart5"]["option"]}
                func={customerCharts["chart5"]["func"]}
                drilldown_data={customerCharts["chart5"]["drilldown_data"]}
              />
            </div>

            {/* 
          <div >
            <ChartFunc
              chartRef={chartRef}
              option={customerCharts["chart5"]["option"]}
              func={customerCharts["chart5"]["func"]}
              drilldown_data={customerCharts["chart5"]["drilldown_data"]}
            />
          </div>
          */}
          </div>
          <Loading isOpen={loading} />
        </div>
      ) : (
        <Loading isOpen={true} />
      )}
    </Page>
  );
};

export default CustomerView;
