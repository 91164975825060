import axios from "axios";

//const BASE_URL = "http://localhost:8050/api/";
const BASE_URL = 'https://dev.ai4chef.com/api/';
axios.defaults.withCredentials = true;

export default axios.create({
  baseURL: BASE_URL,
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});
