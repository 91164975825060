// React
import { useState, useEffect, useRef } from "react";

// contexts
import { useApi } from "../../../utils/hooks/useAxiosPrivate";
import useTokenVerifier from "../../../utils/hooks/useTokenVerifier";
import useDarkMode from "../../Profile/usedarkmode";

// components
import Page from "../page";
import ChartComponent from "../../../components/reusable/charts/chartComponent";
import SelectComponent from "../../../components/reusable/select/select";
import NWeeksFormatter from "../../../components/reusable/select/nweeks";
import MyTable from "../../../components/reusable/table/table-component";
import Loading from "../../../components/reusable/loading/loading";
import NWeeksHelp from "../../../components/reusable/NWeeksHelp";
import {
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card"; // Import Card components

// icons / images
import { IoPieChart } from "react-icons/io5";

type ChartOptions = Record<string, any>;

const MarginAnalysis = () => {
  const [tokenVerified, setTokenVerified] = useState(false);

  // Callback function to update token verification status
  const handleTokenVerified = () => {
    setTokenVerified(true);
  };
  useTokenVerifier({
    onTokenVerified: handleTokenVerified,
    log: "MARGIN ANALYSIS PAGE",
  });

  const [loading, setLoading] = useState(true);
  const [chartLoaded, setChartLoaded] = useState(false);
  const { get } = useApi();
  const [nweeks, setNweeks] = useState<number>(4);
  const [graphData, setGraphData] = useState<Record<string, ChartOptions>>({
    q11: {},
    q12: {},
    q21: {},
    q22: {},
    q31: {},
  });
  const [lastSalesDate, setLastSalesDate] = useState();
  const chartRef = useRef(null);
  const darkMode = useDarkMode();
  const columns = [
    { headerName: "Nome", field: "name" },
    { headerName: "Margem Acumulada", field: "margemAcc" },
    { headerName: "Número de Doses", field: "nDoses" },
    { headerName: "Margem Média", field: "margemAvg" },
  ];

  function handleChartFinished() {
    if (chartRef.current) {
      chartRef.current.getEchartsInstance().resize();
    }
    setChartLoaded(true);
  }

  function buildBubbleChart(data) {
    if (!data) {
      return undefined;
    }
    return {
      title: {
        text: "Comparação de Pratos/Receitas entre semana inicial e final",
        subtext: "Tamanho do circulo representa volume de vendas",
        left: "5%",
        top: "3%",
      },
      legend: {
        right: "3%",
        top: "10%",
        data: ["Inicio", "Fim"],
      },
      grid: {
        left: "8%",
        top: "10%",
      },
      xAxis: {
        name: "Margem",
        nameLocation: "middle",
        nameGap: 30,
        splitLine: {
          lineStyle: {
            type: "dashed",
          },
        },
      },
      yAxis: {
        name: "Número de doses",
        nameLocation: "middle",
        nameGap: 25,
        splitLine: {
          lineStyle: {
            type: "dashed",
          },
        },
        scale: true,
      },
      series: [
        {
          name: "Inicio",
          data: data[0],
          type: "scatter",
          symbolSize: (data) => Math.sqrt(data[2]),
          emphasis: {
            focus: "series",
            label: {
              show: true,
              formatter: (param) => param.data[3].replace(/ /g, "\n"),
              padding: 0,
              fontSize: 10,
              position: "inside",
            },
          },
          itemStyle: {
            shadowBlur: 10,
            shadowColor: "rgba(120, 36, 50, 0.5)",
            shadowOffsetY: 5,
            color: {
              type: "radial",
              x: 0.4,
              y: 0.3,
              r: 1,
              colorStops: [
                { offset: 0, color: "rgb(251, 118, 123)" },
                { offset: 1, color: "rgb(204, 46, 72)" },
              ],
            },
          },
        },
        {
          name: "Fim",
          data: data[1],
          type: "scatter",
          symbolSize: (data) => Math.sqrt(data[2]),
          emphasis: {
            focus: "series",
            label: {
              show: true,
              formatter: (param) => param.data[3].replace(/ /g, "\n"),
              padding: 0,
              fontSize: 10,
              position: "inside",
            },
          },
          itemStyle: {
            shadowBlur: 10,
            shadowColor: "rgba(25, 100, 150, 0.5)",
            shadowOffsetY: 5,
            color: {
              type: "radial",
              x: 0.4,
              y: 0.3,
              r: 1,
              colorStops: [
                { offset: 0, color: "rgb(129, 227, 238)" },
                { offset: 1, color: "rgb(25, 183, 207)" },
              ],
            },
          },
        },
      ],
    };
  }

  useEffect(() => {
    const controller = new AbortController();

    const fetchGraphData = async () => {
      try {
        const promises = [
          get(`dash6_q11/?nweeks=${nweeks}`),
          get(`dash6_q12/?nweeks=${nweeks}`),
          get(`dash6_q21/?nweeks=${nweeks}`),
          get(`dash6_q22/?nweeks=${nweeks}`),
          get(`dash6_q31/?nweeks=${nweeks}`),
          get("dash4_sales_last_date"),
        ];

        const responses = await Promise.all(promises);
        const [q11Data, q12Data, q21Data, q22Data, q31Data, lastSalesDateData] =
          responses;

        setGraphData({
          q11: q11Data.data,
          q12: q12Data.data,
          q21: q21Data.data,
          q22: q22Data.data,
          q31: q31Data.data,
        });
        setLastSalesDate(lastSalesDateData.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };

    if (nweeks) {
      fetchGraphData();
    }

    return () => {
      controller.abort();
    };
  }, [nweeks]);

  return (
    <Page title={"Análise de Margem"} icon={<IoPieChart size={32} />}>
      {tokenVerified ? (
        <div className="ion-padding">
          <div>
            <div className="flex flex-inline justify-between mb-5">
              <CardHeader>
                <CardTitle>Análise de Margem</CardTitle>
                <CardDescription>
                  Veja quais as receitas que mais contribuem para a margem do
                  seu restaurante
                </CardDescription>
              </CardHeader>
              <div className="mt-5 mil:pt-0">
                <SelectComponent
                  formatter={NWeeksFormatter}
                  darkMode={darkMode}
                  onChange={setNweeks}
                />
              </div>
            </div>

            {/* Section for Chart Components */}
            <div className="grid xl:grid-cols-2 gap-4 padding mt-5">
              <ChartComponent
                chartRef={chartRef}
                option={graphData.q11}
                handleChartFinished={handleChartFinished}
                ChangeScale={false}
              />
              <ChartComponent
                chartRef={chartRef}
                option={graphData.q12}
                handleChartFinished={handleChartFinished}
                ChangeScale={false}
              />
              <ChartComponent
                chartRef={chartRef}
                option={graphData.q21}
                handleChartFinished={handleChartFinished}
                ChangeScale={false}
              />
              <MyTable
                rowData={graphData.q22}
                columnDefs={columns}
                darkMode={darkMode}
                title="Rank de Margem Bruta acumulada das Receitas"
              />
              <div className="xl:col-span-2">
                <CardHeader className="mt-10 mb-5">
                  <CardTitle>Análise de Performance</CardTitle>
                  <CardDescription>
                    Compare a performance de diferentes receitas entre as
                    semanas de início e fim
                  </CardDescription>
                </CardHeader>
                <ChartComponent
                  chartRef={chartRef}
                  option={buildBubbleChart(graphData.q31)}
                  handleChartFinished={handleChartFinished}
                  ChangeScale={false}
                />
              </div>
            </div>
          </div>
          <Loading isOpen={loading} />
        </div>
      ) : (
        <Loading isOpen={true} />
      )}
    </Page>
  );
};

export default MarginAnalysis;
