// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

  .scrollInvisible::-webkit-scrollbar {
    background: transparent;
  }
  
  .scrollInvisible::-webkit-scrollbar-thumb {
    background: transparent;
  }`, "",{"version":3,"sources":["webpack://./src/assets/css/inisibleScroll.css"],"names":[],"mappings":";;EAEE;IACE,uBAAuB;EACzB;;EAEA;IACE,uBAAuB;EACzB","sourcesContent":["\n\n  .scrollInvisible::-webkit-scrollbar {\n    background: transparent;\n  }\n  \n  .scrollInvisible::-webkit-scrollbar-thumb {\n    background: transparent;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
