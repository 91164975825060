import './loader.css';
import { useMemo } from 'react';

const Loading = ({ isOpen }) => {

  const randomLoader = useMemo(() => {
    const randomNumber: number = Math.floor(Math.random() * 6);
    const className: string = "loader" + randomNumber;
    return className;
  }, []);

  return (
    <>
      {isOpen && (
        <div className="loader-overlay">
          <div className={randomLoader}></div>
        </div>
      )}
    </>
  );
}

export default Loading;
