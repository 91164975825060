import React from "react";
import ReactDOM from "react-dom";
import App from "./app/App";
import * as serviceWorkerRegistration from "./app/serviceWorkerRegistration";
import reportWebVitals from "./app/reportWebVitals";
import './index.css';
// import './assets/css/index'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorkerRegistration.register();
reportWebVitals();
