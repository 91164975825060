// React
import { useCallback, useEffect, useRef, useState } from "react";

// Contexts
import useDarkMode from "../../Profile/usedarkmode";
import { useCat } from "../../../utils/contexts/CategoryContext";
import { useApi } from "../../../utils/hooks/useAxiosPrivate";
import useTokenVerifier from "../../../utils/hooks/useTokenVerifier";

// Components
import Page from "../page";
import ChartComponent from "../../../components/reusable/charts/chartComponent";
import SelectComponent from "../../../components/reusable/select/select";
import NWeeksHelp from "../../../components/reusable/NWeeksHelp";
import CategoryFormatter from "../../../components/reusable/select/categories";
import NWeeksFormatter from "../../../components/reusable/select/nweeks";
import Toaster from "../../../components/reusable/Toaster";
import Loading from "../../../components/reusable/loading/loading";

// Icons
import { IoPricetag } from "react-icons/io5";

// Constants
const INITIAL_CHART_STATE = {};

const INITIAL_WEEKS = 4;

const D3FoodCostRecipesPage = () => {
  const [tokenVerified, setTokenVerified] = useState(false);
  const { Categories } = useCat();
  const { get } = useApi();
  const darkMode = useDarkMode();

  // Callback function to update token verification status
  const handleTokenVerified = () => {
    setTokenVerified(true);
  };
  useTokenVerifier({
    onTokenVerified: handleTokenVerified,
    log: "FOODCOSTRECIPES PAGE",
  });

  const [chartData, setChartData] = useState({
    q11: INITIAL_CHART_STATE,
    q12: INITIAL_CHART_STATE,
    q21: INITIAL_CHART_STATE,
    q22: INITIAL_CHART_STATE,
    q31: INITIAL_CHART_STATE,
    q41: INITIAL_CHART_STATE,
    q42: INITIAL_CHART_STATE,
  });
  const [nweeks, setNweeks] = useState(INITIAL_WEEKS);
  const [category, setCategory] = useState("");
  const [loading, setLoading] = useState(false);
  const [chartLoaded, setChartLoaded] = useState(false);
  const chartRef = useRef(null);

  function handleChartFinished() {
    if (chartRef.current) {
      chartRef.current.getEchartsInstance().resize();
    }
    setChartLoaded(true);
  }

  useEffect(() => {
    if (Categories.length > 0) {
      setCategory(Categories[0]);
    }
  }, [Categories]);

  const fetchChartData = useCallback(async (endpoint, params = {}) => {
    const queryString = new URLSearchParams(params).toString();
    const url = `dash3_${endpoint}/${queryString ? `?${queryString}` : ""}`;

    try {
      setLoading(true);
      const response = await get(url);
      setChartData((prevData) => ({
        ...prevData,
        [endpoint]: response.data,
      }));
    } catch (error) {
      console.error(`Error fetching data for ${endpoint}:`, error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const endpoints = ["q11", "q12", "q21", "q22", "q31", "q32", "q41", "q42"];
    endpoints.forEach((endpoint) => {
      const params = endpoint.startsWith("q41") || endpoint.startsWith("q31")
        ? { nweeks, category }
        : { nweeks };
      fetchChartData(endpoint, params);
    });
  }, [nweeks, category, fetchChartData]);

  const renderCharts = () => {
    const chartConfigs = [
      { option: chartData.q11 },
      { option: chartData.q12 },
      { option: chartData.q21 },
      { option: chartData.q22 },
      { option: chartData.q42 },
    ];

    return chartConfigs.map((config, index) => (
      <ChartComponent
        key={index}
        handleChartFinished={handleChartFinished}
        chartRef={chartRef}
        option={config.option}
        ChangeScale={false}
      />
    ));
  };

  const renderCategoryCharts = () => {
    if (Categories.length === 0) return null;

    return (
      <>
        <div className="py-10">
          <SelectComponent
            formatter={CategoryFormatter}
            darkMode={darkMode}
            onChange={setCategory}
          />
        </div>
        <div className="grid xl:grid-cols-2 gap-4 ">
          <ChartComponent
            chartRef={chartRef}
            option={chartData.q41}
            ChangeScale={false}
          />
          <ChartComponent
            handleChartFinished={handleChartFinished}
            chartRef={chartRef}
            option={chartData.q31}
            ChangeScale={false}
          />
        </div>
      </>
    );
  };


  return (
    <Page title={"Visão de Receitas"} icon={<IoPricetag size={32} />}>
      {tokenVerified ? (
        <div>
          <div className="padding">
            <div className="py-4 flex items-center">
              <div className="w-[30%]">
                <SelectComponent
                  formatter={NWeeksFormatter}
                  darkMode={darkMode}
                  onChange={setNweeks}
                />
              </div>
              <div className="w-[70%]">
                <NWeeksHelp nweeks={nweeks} />
              </div>
            </div>
            <div className="grid xl:grid-cols-2 gap-4 ">{renderCharts()}</div>
            {renderCategoryCharts()}
          </div>
          <Loading isOpen={loading} />
        </div>
      ) : (
        <Loading isOpen={true} />
      )}
    </Page>
  );
};
export default D3FoodCostRecipesPage;
