// React
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Hooks
import useDarkMode from "../../../Profile/usedarkmode";
import useTokenVerifier from "../../../../utils/hooks/useTokenVerifier";

// Components
import Loading from "../../../../components/reusable/loading/loading";
import Page from "../../page";
import Toaster from "../../../../components/reusable/Toaster";
import { Button } from "../../../../components/ui/button";
import { Label } from "../../../../components/ui/label";

// Icons
import { IoRestaurant } from "react-icons/io5";

// Utils
import { axiosPrivate } from "../../../../utils/api/axios";
import { formatDate } from "../../../../utils/funtions";
import Modal from "../../../../components/reusable/Modal";

const priorities = [
  { label: "Baixa", value: "LOW" },
  { label: "Média", value: "MEDIUM" },
  { label: "Alta", value: "HIGH" },
];

const types = [
  { label: "Dar Feedback", value: "FEEDBACK" },
  { label: "Erro na aplicação", value: "BUGS" },
  { label: "Suporte", value: "SUPPORT" },
  { label: "Pedido de informação", value: "INFO_ASK" },
  { label: "Classificação de SKU", value: "CLASS_SKU" },
  { label: "Classificação de produto", value: "CLASS_PROD" },
  // { label: "Test", value: "TEST" },
];

const status = [
  { label: "Aberto", value: "OPEN" },
  { label: "Em progresso", value: "PROGRESS" },
  { label: "Fechado", value: "CLOSED" },
  // { label: "Test", value: "TEST" },
];

const TicketList = () => {
  const [tokenVerified, setTokenVerified] = useState(false);

  // Callback function to update token verification status
  const handleTokenVerified = () => {
    setTokenVerified(true);
  };
  useTokenVerifier({
    onTokenVerified: handleTokenVerified,
    log: "RECIPES PAGE",
  });

  const [tickets, setTickets] = useState([]);
  const [createOpen, setCreateOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [tipo, setTipo] = useState("");
  const [prioridade, setPrioridade] = useState("LOW");

  // Get tickets
  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const response = await axiosPrivate.get("/tickets");
        setTickets(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchTickets();
  }, []);

  // Create ticket
  const createTicket = async () => {
    try {
      const response = await axiosPrivate.post("/tickets/create", {
        title: title,
        message: message,
        type: tipo,
        priority: prioridade,
      });
      if (response) {
        setTickets((prevTickets) => [...prevTickets, response.data]);
        Toaster.show("Ticket criado com sucesso!", "success");
      }
    } catch (error) {
      console.error(error);
      Toaster.show("Erro ao criar ticket.", "error");
    } finally {
      setCreateOpen(false);
      setTitle("");
      setMessage("");
      setTipo("");
      setPrioridade("");
    }
  };

  const darkMode = useDarkMode();

  return (
    <Page title={"Tickets"} icon={<IoRestaurant size={32} />}>
      {tokenVerified ? (
        <div>
          {tickets && (
            <div
              style={{ paddingBottom: "20px" }}
              className="w-[80%] m-auto text-black"
            >
              <div className="flex items-center justify-between mt-10">
                <p
                  style={{ color: darkMode ? "white" : "black" }}
                  className="text-2xl font-semibold text-blue-dark"
                >
                  Tickets de suporte
                </p>
                <div>
                  <Button
                    onClick={() => setCreateOpen(true)}
                    style={{ background: darkMode ? "#422655" : "" }}
                    className="text-white bg-blue-dark p-3 rounded hover:scale-110 transition-transform duration-300"
                  >
                    + Criar Ticket
                  </Button>
                </div>
              </div>

              {tickets && tickets.length > 0 ? (
                <table className="min-w-full divide-y divide-gray-200 shadow-2xl rounded mt-5">
                  <thead className="bg-blue-dark rounded text-white">
                    <tr>
                      <th
                        style={{ background: darkMode ? "#422655" : "" }}
                        className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider"
                      >
                        ID
                      </th>
                      <th
                        style={{ background: darkMode ? "#422655" : "" }}
                        className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider"
                      >
                        Titulo
                      </th>
                      <th
                        style={{ background: darkMode ? "#422655" : "" }}
                        className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider"
                      >
                        Restaurante
                      </th>
                      <th
                        style={{ background: darkMode ? "#422655" : "" }}
                        className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider"
                      >
                        Tipo
                      </th>
                      <th
                        style={{ background: darkMode ? "#422655" : "" }}
                        className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider"
                      >
                        Prioridade
                      </th>
                      <th
                        style={{ background: darkMode ? "#422655" : "" }}
                        className="px-6 py-2 text-left text-xs leading-4 font-medium uppercase tracking-wider"
                      >
                        Status
                      </th>
                      <th
                        style={{ background: darkMode ? "#422655" : "" }}
                        className="px-6 py-2 text-left text-xs leading-4 font-medium uppercase tracking-wider"
                      >
                        Criado em
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    style={{ backgroundColor: darkMode ? "#262853" : "" }}
                    className="bg-white divide-y divide-gray-200"
                  >
                    {tickets &&
                      tickets.map((item, index) => (
                        <tr key={index}>
                          <td
                            style={{ color: darkMode ? "white" : "black" }}
                            className="px-6 py-2 whitespace-no-wrap text-gray-600 text-semibold"
                          >
                            <Link to={`/tickets/detail/${item.ticket_id}`}>
                              {item.ticket_id}
                            </Link>
                          </td>
                          <td
                            style={{ color: darkMode ? "white" : "black" }}
                            className="px-6 py-2 whitespace-no-wrap text-gray-600 text-semibold"
                          >
                            <Link to={`/tickets/detail/${item.ticket_id}`}>
                              {item.ticket_title}
                            </Link>
                          </td>
                          <td
                            style={{ color: darkMode ? "white" : "black" }}
                            className="px-6 py-2 whitespace-no-wrap text-gray-600"
                          >
                            {item.rest_site}
                          </td>
                          <td
                            style={{ color: darkMode ? "white" : "black" }}
                            className="px-6 py-2 whitespace-no-wrap text-gray-600"
                          >
                            {
                              types.find((t) => t.value === item.ticket_type)
                                ?.label
                            }
                          </td>
                          <td className="px-6 py-2 whitespace-no-wrap text-gray-600">
                            <span
                              className={`px-4 py-1 rounded-full whitespace-no-wrap text-gray-600 ${
                                item.ticket_priority === "LOW"
                                  ? "bg-green-600 text-white"
                                  : item.ticket_priority === "MEDIUM"
                                  ? "bg-yellow-400"
                                  : "bg-red-600 text-white"
                              }`}
                            >
                              {
                                priorities.find(
                                  (t) => t.value === item.ticket_priority
                                )?.label
                              }
                            </span>
                          </td>
                          <td
                            style={{ color: darkMode ? "white" : "black" }}
                            className="px-6 py-2 whitespace-no-wrap text-gray-600"
                          >
                            {
                              status.find((t) => t.value === item.ticket_status)
                                ?.label
                            }
                          </td>
                          <td
                            style={{ color: darkMode ? "white" : "black" }}
                            className="px-6 py-2 whitespace-no-wrap text-gray-600"
                          >
                            {formatDate(item.zdate)}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              ) : (
                <div
                  style={{ color: darkMode ? "white" : "black" }}
                  className="text-center text-2xl text-blue-dark"
                >
                  Sem tickets de suporte abertos.
                </div>
              )}
            </div>
          )}
          <Modal isOpen={createOpen} setIsOpen={setCreateOpen}>
            <div className="flex flex-col items-center justify-center">
              <h2 className="text-2xl font-bold mb-4 text-center">
                Criar Ticket
              </h2>
              <div className="w-full px-10">
                <div>
                  <Label
                    style={{ color: darkMode ? "white" : "black" }}
                    className="block text-gray-700 text-sm font-bold mb-2"
                  >
                    Titulo
                  </Label>
                  <input
                    style={{ backgroundColor: darkMode ? "#262853" : "" }}
                    className="w-full bg-gray-200 border rounded py-2 px-3"
                    min={1}
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>

                <div className="flex mt-10">
                  <div className="w-1/2 pr-2">
                    <Label
                      style={{ color: darkMode ? "white" : "black" }}
                      className="block text-gray-700 text-sm font-bold mb-2"
                    >
                      Tipo
                    </Label>
                    <select
                      className="w-full bg-gray-200 border rounded py-2 px-3"
                      onChange={(e) => setTipo(e.target.value)}
                    >
                      {types.map((priority, index) => (
                        <option key={index} value={priority.value}>
                          {priority.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="w-1/2 pl-2">
                    <Label
                      style={{ color: darkMode ? "white" : "black" }}
                      className="block text-gray-700 text-sm font-bold mb-2"
                    >
                      Prioridade
                    </Label>
                    <select
                      className="w-full bg-gray-200 border rounded py-2 px-3"
                      onChange={(e) => {
                        setPrioridade(e.target.value);
                      }}
                    >
                      {priorities.map((priority, index) => (
                        <option key={index} value={priority.value}>
                          {priority.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="mt-10 mb-4">
                  <Label
                    style={{ color: darkMode ? "white" : "black" }}
                    className="block text-gray-700 text-sm font-bold mb-2"
                  >
                    Descrição
                  </Label>
                  <textarea
                    style={{ backgroundColor: darkMode ? "#262853" : "" }}
                    className="w-full bg-gray-200 border rounded py-2 px-3"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </div>
                <Button
                  onClick={createTicket}
                  type="submit"
                  disabled={false}
                  className="bg-blue-dark w-full p-5 hover:bg-blue-700 text-white font-bold rounded focus:outline-none focus:shadow-outline"
                >
                  Enviar Ticket
                </Button>
              </div>
            </div>
          </Modal>
        </div>
      ) : (
        <Loading isOpen={true} />
      )}
    </Page>
  );
};

export default TicketList;
