import { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import { DarkModeProvider } from "../../../utils/contexts/theme.context";
import useDarkMode from "../../../pages/Profile/usedarkmode";

import { darkMode } from "../../../pages/Profile/Settings";
import { MdBarChart } from "react-icons/md";
import { IoCloseOutline } from "react-icons/io5";
import Modal from "../Modal";
import Loading from "../loading/loading";
import { useSidebar } from "../../../utils/contexts/SideBarContext";

function Chart({
  option,
  width,
  legendHeight,
  chartRef,
  ChangeScale,
  updatedChartData,
  handleChartFinished,
  dinamicHeight,
}) {
  function handleWidth(width, legendHeight) {
    const conditions = [
      { limit: 500, multiplier: 10 },
      { limit: 550, multiplier: 5 },
      { limit: 650, multiplier: 3 },
      { limit: 768, multiplier: 1 },
      { limit: 850, multiplier: 0.5 },
      { limit: 1200, multiplier: -1.5 },
    ];

    const { multiplier } = conditions.find(({ limit }) => width <= limit) || {
      multiplier: 1,
    };

    return !Number.isNaN(legendHeight) &&
      legendHeight !== undefined &&
      width < 1200
      ? 510 + legendHeight * multiplier
      : 510;
  }

  return (
    <div className="chart-container">
      {option !== null && option !== undefined ? (
        <ReactECharts
          style={dinamicHeight && { height: handleWidth(width, legendHeight) }}
          ref={chartRef}
          option={ChangeScale ? updatedChartData : option}
          onEvents={{ finished: handleChartFinished }}
        />
      ) : (
        <div
          style={{
            height: dinamicHeight && handleWidth(width, legendHeight),
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white", // Set background to white
          }}
        >
          <p style={{ color: darkMode ? "white" : "black" }}>Loading...</p>
        </div>
      )}
    </div>
  );
}

function ScaleButton({ isLogScale, toggleScale, darkMode }) {
  return (
    <button
      className="changeScale"
      onClick={toggleScale}
      title={isLogScale ? "Change to value" : "Change to log"}
      style={{
        color: darkMode ? "white" : "black",
        backgroundColor: "transparent",
        border: "none",
        padding: "0",
        cursor: "pointer",
      }}
    >
      <MdBarChart
        className="changeScaleIcon"
        title="Mudar Escala"
        style={{ marginRight: "5px" }}
      />
    </button>
  );
}

function HelpContainer({ graphDescription, darkMode, position }) {
  const [showModal, setShowModal] = useState(false);

  const toggleHelp = () => {
    setShowModal(!showModal);
  };
  var modalStyle;
  switch (position) {
    case "left":
      modalStyle = {
        transform: "translateX(-25%)",
      };
      break;
    case "right":
      modalStyle = {
        transform: "translateX(25%)",
      };
      break;
    default:
      modalStyle = {
        transform: "translateX(0%)",
      };
      break;
  }
  return (
    <div className="help-container">
      <button
        className="helpButton"
        onClick={toggleHelp}
        style={{
          color: darkMode ? "white" : "black",
          backgroundColor: darkMode ? "#6c2f80" : "#d6d7d5",
        }}
      >
        ?
      </button>
      <div className="modal-left">
        <Modal isOpen={showModal} setIsOpen={setShowModal}>
          <div>
            <div>
              <p>Descriçao do Gráfico</p>
              <div slot="end">
                <button onClick={() => setShowModal(false)}>
                  <IoCloseOutline title="Fechar" size={32} />
                </button>
              </div>
            </div>
          </div>
          <div className="ion-padding">
            <div style={{ padding: "20px 20px", overflow: "auto" }}>
              {graphDescription}
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}

// function ClientModal({ showModal, setShowModal, clients, columns, segment, darkMode }) {
//   const closeModal = () => {
//     setShowModal(false);
//   };

//   return (
//     <Modal isOpen={showModal} onDidDismiss={closeModal}>
//       <div className="scrollbar" style={{ width: "100%", padding: "30px 30px", overflowY: "auto", height: "80vh" }}>
//         <div>
//           <MyTable rowData={clients} columnDefs={columns} title={`Lista de clients do tipo ${segment}`} darkMode={darkMode} />
//         </div>
//       </div>
//     </Modal>
//   );
// }

// Main ChartComponent using extracted components
function ChartComponent({
  chartRef,
  option,
  handleChartFinished = null,
  ChangeScale = false,
  loading = false,
  graphDescription = <p>Inserir descriçao</p>,

  // clientPopUp = false,
  dinamicHeight = true,
  positionDescription = "right",
}) {
  const colors = [
    "#CC33FF",
    "#9900CC",
    "#FF0066",
    "#FF66CC",
    "#FFCCFF",
    "#FF99CC",
    "#FF6699",
    "#FF3366",
    "#993399",
    "#CC3399",
    "#FF3399",
    "#FF6699",
    "#FF99AA",
    "#FF99BB",
    "#FF99DD",
    "#FF66DD",
    "#FF6699",
    "#FF3366",
    "#FF0033",
    "#FF3366",
  ];

  //console.log("Option: " + JSON.stringify(option))
  let legendHeight = 0;
  const [width, setWidth] = useState(window.innerWidth);
  const [isLogScale, setIsLogScale] = useState(false);
  // const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [option]);



  const darkMode = useDarkMode();
  const textColor = darkMode ? "white" : "black";

  const toggleScale = () => {
    setIsLogScale(!isLogScale);
  };

  if (ChangeScale) {
    option = updateChartData(option, isLogScale, textColor);
  }

  if (option !== undefined) {
    if (
      (option &&
        option.series &&
        option.series[0] &&
        option.series[0].data &&
        option.series[0].data.length === 0) ||
      (Object.keys(option).length === 0 && option.constructor === Object) ||
      (option && option.series && option.series.length === 0)
    ) {
      return <div></div>;
    }
    option = { ...option, color: colors };
    legendHeight = styleChart(option, textColor, width);
  } else {
    return <div></div>;
  }

  //FOR CUSTOMERS ANALYSIS PURPOSES
  // const HandlePieClick = async (params) => {
  //   const clickedSectionName = params.name;
  //   setSegment(clickedSectionName);
  //   const sections = [
  //     { name: "Melhor"},
  //     { name: "Muito Leal"},
  //     { name: "Potencial Leal"},
  //     { name: "Churned"},
  //     { name: "Em risco"},
  //     { name: "Novo"},
  //     { name: "Perdidos"},
  //     { name: "Piores"},
  //   ];
  //   if(clickedSectionName !== "nan"){const { name } = sections.find(({name}) => clickedSectionName === name);
  //   const controller = new AbortController();
  //   let isMounted = true;
  //   try {
  //     const params = { an_id: customersN, segment: name }
  //     const url = params
  //       ? `customer_segment_list/?${Object.entries(params)
  //         .map(([key, value]) => `${key}=${value}`)
  //         .join("&")}`
  //       : `customer_segment_list`;
  //     const response = await axiosPrivate.get(url, {
  //       signal: controller.signal
  //     });
  //     const jsonObjects = response.data.map(value => ({ id: value }));
  //     console.log("GETTED " + JSON.stringify(jsonObjects))
  //     setClients(jsonObjects);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  //   return () => {
  //     isMounted = false;
  //     controller.abort();
  //   };}
  // };


  if (width < 1200) {
    positionDescription = "center";
  }



  return (
      <div
        className={`relative rounded-lg p-10 chart-container`}
        style={{
          backgroundColor: darkMode ? "#262853" : "white",
        }}
      >
        <Chart
          option={option}
          width={width}
          legendHeight={legendHeight}
          chartRef={chartRef}
          ChangeScale={ChangeScale}
          updatedChartData={option}
          handleChartFinished={handleChartFinished}
          dinamicHeight={dinamicHeight}
          // HandlePieClick={customers && HandlePieClick}
        />
        {/* {loading && <Loading isOpen={true} />} */}
        {ChangeScale && (
          <ScaleButton
            isLogScale={isLogScale}
            toggleScale={toggleScale}
            darkMode={darkMode}
          />
        )}
        {/* <HelpContainer
          position={positionDescription}
          graphDescription={graphDescription}
          darkMode={darkMode}
        /> */}
        {/* {clientPopUp && (
          <ClientModal
            showModal={showModal}
            setShowModal={setShowModal}
            darkMode={darkMode}
          />
        )} */}
      </div>
  );
}

//AUX FUNCTIONS
function getMaxStringLength(listOfStrings) {
  let maxLength = 0;
  for (const str of listOfStrings) {
    if (typeof str === "string" && str.length > maxLength) {
      maxLength = str.length;
    }
  }
  return maxLength;
}

function addNewlinesAndCountMax(originalList) {
  let bottom = 0;

  const newList = originalList.map((item) => {
    const newElement = item.split(" ").join("\n");
    const newlineCount = (newElement.match(/\n/g) || []).length;

    if (newlineCount > bottom) {
      bottom = newlineCount;
    }

    return newElement;
  });

  return { newList, bottom };
}

function insertNewlines(text, width) {
  const words = text.split(/\s+|(?<=\s)(?=\S)/);
  const wordsPerLine = width <= 600 ? 4 : 5;
  const lines = [];

  for (let i = 0; i < words.length; i += wordsPerLine) {
    const line = words.slice(i, i + wordsPerLine).join(" ");
    lines.push(line);
  }

  return lines.join("\n");
}

function updateChartData(option, isLogScale, textColor) {
  if (isLogScale) {
    const yAxisConfig = {
      type: "log",
      logBase: 10,
      axisLabel: { color: textColor },
    };
    return { ...option, yAxis: yAxisConfig };
  } else {
    const yAxisConfig = {
      type: "value",
      axisLabel: { color: textColor },
    };
    return { ...option, yAxis: yAxisConfig };
  }
}

//STYLING FUNCTIONS
function styleChart(
  option,
  textColor = darkMode ? "white" : "black",
  width = window.innerWidth
) {
  let candle = 0;

  //add a save as image option to the chart
  if (!option.toolbox) {
    option.toolbox = {
      feature: {
        saveAsImage: {
          title: "Save",
          backgroundColor: darkMode ? "#262853" : "white",
        },
      },
    };
  } else {
    option.toolbox.feature.saveAsImage = {
      title: "Save",
      backgroundColor: darkMode ? "#262853" : "white",
    };
  }

  // option.datazoom = {
  //     type: 'slider',  // on bar charts
  //     start: 0,
  //     end: 100

  // }

  let legendLength = styleLegend(option, textColor);
  styleTitle(option, textColor, width);
  styleAxis(option, textColor);
  candle = styleSeries(option, textColor, candle);
  styleGraphic(option, textColor, width);
  const bottom = styleGrid(option, legendLength, candle);
  styleVisualMap(option, textColor, width);
  styleTooltip(option);
  return bottom;
}

function styleLegend(option, textColor) {
  let legendLength;
  if (option.legend !== undefined) {
    if (option.legend.data !== undefined) {
      legendLength = option.legend.data.length;
    }
    option.legend.textStyle = { color: textColor };
  }
  return legendLength;
}

function styleTitle(option, textColor, width) {
  if (option.title !== undefined) {
    option.title.left = "center";
    option.title.top = "0%";
    option.title.textStyle = { color: textColor };
    option.title.subtextStyle = { color: textColor };
    if (option.title.text !== undefined) {
      option.title.text = insertNewlines(option.title.text, width);
    }
    if (option.title.subtext !== undefined) {
      option.title.subtext = insertNewlines(option.title.subtext, width);
    }
  }
}

function styleAxis(option, textColor) {
  if (option.yAxis !== undefined) option.yAxis.axisLabel = { color: textColor };
  if (option.xAxis !== undefined) option.xAxis.axisLabel = { color: textColor };
}

function styleSeries(option, textColor, candle) {
  if (option.series !== undefined)
    option.series.forEach((series, index) => {
      series.label = { color: textColor };
      candle = styleSeriesType(series, option, candle);
      if (series.emphasis !== undefined)
        series.emphasis.label = { ...series.emphasis.label, color: textColor };
    });
  return candle;
}

function styleSeriesType(series, option, candle) {
  if (series.type === "candlestick") {
    series.itemStyle = {
      color: "red",
      color0: "green",
      borderColor: "red",
      borderColor0: "green",
    };
    if (option.title !== undefined) {
      !option.title.text
        .toString()
        .toLowerCase()
        .includes("maior amplitude de Margem Bruta Unitária".toLowerCase())
        ? (series.itemStyle = {
            color: "red",
            color0: "green",
            borderColor: "red",
            borderColor0: "green",
          })
        : (series.itemStyle = {
            color: "green",
            color0: "red",
            borderColor: "green",
            borderColor0: "red",
          });
    }
    if (option.xAxis.data !== undefined) {
      const { newList, bottom } = addNewlinesAndCountMax(option.xAxis.data);
      option.grid = {};
      candle = bottom;
      option.xAxis.data = newList;
    }
  } else if (series.type === "pie") {
    stylePieSeries(series);
  }
  return candle;
}

function stylePieSeries(series) {
  series.radius = ["40%", "50%"];
  series.avoidLabelOverlap = "false";
  series.itemStyle = {};
  series.center = ["50%", "45%"];
  if (series.label !== undefined) {
    series.label.show = "true";
    series.label.position = "outside";
  }
  if (series.emphasis !== undefined) {
    if (series.emphasis.label !== undefined) {
      series.emphasis.label.show = "true";
      series.emphasis.label.fontSize = 14;
      series.emphasis.label.fontWeight = "bold";
    }
  }
}

function styleGraphic(option, textColor, width) {
  if (option.graphic !== undefined) {
    option.graphic.forEach((graphic, index) => {
      graphic.style = { ...graphic.style, fill: textColor };
      if (graphic.style.text !== undefined)
        graphic.style.text = insertNewlines(graphic.style.text, width);
    });
  }
}

function styleTooltip(option) {
  if (option.tooltip !== undefined) {
    option.tooltip.textStyle = { color: "black" };
    if (option.tooltip.formatter !== undefined) {
      const tooltipFunctionString = option.tooltip.formatter.toString();
      const tooltipContentRegex = /return\s+'(.*)';/;
      const match = tooltipFunctionString.match(tooltipContentRegex);
      const extractedTooltipContent = match ? match[1] : "";
      option.tooltip = {
        formatter: extractedTooltipContent,
      };
    }
  }
}

function styleGrid(option, legendLength, candle) {
  if (option.grid !== undefined) {
    option.grid.top = "20%";
    option.grid.right = "5%";
    option.grid.left = "5%";
    var bottom: number;
    candle > 0
      ? (bottom = candle * 4.5)
      : legendLength <= 8
      ? (bottom = 15)
      : legendLength <= 10
      ? (bottom = 25)
      : (bottom = (legendLength / 3) * 4.5);

    if (0 < candle && candle <= 2) {
      bottom = 15;
    }

    option.grid.bottom = `${bottom}%`;
  }
  return bottom;
}

function styleVisualMap(option, textColor, width) {
  if (option.visualMap !== undefined) {
    option.visualMap.textStyle = { color: textColor };
    if (option.title !== undefined) {
      option.title.text.toString().toLowerCase().includes("custo".toLowerCase())
        ? (option.visualMap.inRange = { color: ["green", "yellow", "red"] })
        : (option.visualMap.inRange = { color: ["red", "yellow", "green"] });
      if (
        option.title.text
          .toString()
          .toLowerCase()
          .includes("no Cabaz".toLowerCase())
      ) {
        option.title.text
          .toString()
          .toLowerCase()
          .includes("Descidas".toLowerCase())
          ? (option.visualMap.inRange = {
              color: ["transparent", "yellow", "green"],
            })
          : (option.visualMap.inRange = {
              color: ["transparent", "yellow", "red"],
            });
      }
    }
    option.visualMap.orient = "horizontal";
    option.visualMap.left = "center";
    option.visualMap.bottom = "0%";
    option.grid.top = "15%";
    const max = getMaxStringLength(option.yAxis.data);
    if (max > 20) {
      if (width > 1200) {
        option.grid.left = `50%`;
        option.grid.width = "40%";
      } else {
        option.grid.left = "40%";
        option.grid.width = "50%";
      }
    } else {
      option.grid.left = "25%";
      option.grid.width = "65%";
    }
  }
}
export default ChartComponent;
