// React
import { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";


// Contexts
import useDarkMode from "../../Profile/usedarkmode";
import { useApi } from "../../../utils/hooks/useAxiosPrivate";
import useTokenVerifier from "../../../utils/hooks/useTokenVerifier";

// Components
import Page from "../page";
import Loading from "../../../components/reusable/loading/loading";
import MyTable from "../../../components/reusable/table/table-component";
import SelectComponent from "../../../components/reusable/select/select";
import BackComponent from "../../../components/reusable/goBack/go-back-component";
import NWeeksFormatter from "../../../components/reusable/select/nweeks";

// Icons / Images
import { IoPricetag } from "react-icons/io5";

const INITIAL_WEEKS = 4;

const columns = [
  { headerName: "Ingrediente", field: "ingr_name" },
  { headerName: "Delta", field: "delta" },
  { headerName: "Delta pct", field: "delta_pct" },
  { headerName: "Média", field: "price_avg" },
  { headerName: "Preço inicial(€)", field: "price_ini" },
  { headerName: "Preço final(€)", field: "price_fim" },
  { headerName: "Preço máximo(€)", field: "price_max" },
  { headerName: "Preço mínimo(€)", field: "price_min" },
];

const FoodCostRecipes = () => {
  const [tokenVerified, setTokenVerified] = useState(false);
  const location = useLocation();
  const { get } = useApi();
  const darkMode = useDarkMode();

  const recipe = new URLSearchParams(location.search).get("item");

  const [nweeks, setNweeks] = useState(INITIAL_WEEKS);
  const [loading, setLoading] = useState(true);
  const [ingredients, setIngredients] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const fetchIngredientData = useCallback(async () => {
    setLoading(true);
    try {
      const url = `dash3_list_ingr/?nweeks=${nweeks}&recipe=${recipe}`;
      const response = await get(url);
      setIngredients(response.data);
    } catch (error) {
      console.error("Error fetching ingredient data:", error);
    } finally {
      setLoading(false);
    }
  }, [nweeks, recipe]);

  useEffect(() => {
    fetchIngredientData();
  }, [fetchIngredientData]);

  const filteredIngredients = ingredients.filter((ingredient) =>
    ingredient.ingr_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Callback function to update token verification status
  const handleTokenVerified = () => {
    setTokenVerified(true);
  };
  useTokenVerifier({
    onTokenVerified: handleTokenVerified,
    log: "FOODCOST RECIPES PAGE",
  });

  return (
    <Page
      title={"Custo das Receitas e Ingredientes"}
      icon={<IoPricetag size={32} />}
    >
      {tokenVerified ? (
        <div className="ion-padding">
          <div className="padding">
            <div className="padding">
              <div>
                <p className="welcome">
                  Evolução dos preços de aquisição de bens alimentares
                </p>
              </div>
              <BackComponent />
              <div
                style={{
                  marginTop: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <SelectComponent
                  formatter={NWeeksFormatter}
                  darkMode={darkMode}
                  onChange={setNweeks}
                />
              </div>
            </div>
            <div style={{ paddingBottom: "20px", paddingTop: "50px" }}>
              <div className="homePageText">
                <div
                  style={{ color: darkMode ? "#f4f4f4" : "black" }}
                  className="seeNews"
                >
                  Visão de produtos
                </div>
              </div>
            </div>

            <div className="search">
              {/* <IonSearchbar
                value={searchQuery}
                onIonChange={(e) => setSearchQuery(e.detail.value)}
                placeholder="Pesquisar por ingrediente"
                style={{
                  "--ion-background-color": darkMode ? "#262853" : "white",
                }}
              /> */}
            </div>
            <MyTable
              rowData={filteredIngredients}
              link="/app/foodcost/recipes/ingr?item="
              linkIndex={0}
              columnDefs={columns}
              title={recipe}
              darkMode={darkMode}
            />
            <Loading isOpen={loading} />
          </div>
        </div>
      ) : (
        <Loading isOpen={true} />
      )}
    </Page>
  );
};
export default FoodCostRecipes;
