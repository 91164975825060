// React
import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";

// contexts
import { DarkModeProvider } from "../../../../utils/contexts/theme.context";

// components
import Loading from "../../../../components/reusable/loading/loading";
import Toaster from "../../../../components/reusable/Toaster";
import InfoComponent from "../../../../components/reusable/info/info.component";
import useTokenVerifier from "../../../../utils/hooks/useTokenVerifier";
import useDarkMode from "../../../Profile/usedarkmode";
import { useApi } from "../../../../utils/hooks/useAxiosPrivate";
import { Input } from "../../../../components/ui/input";
import { Label } from "../../../../components/ui/label";
import { Button } from "../../../../components/ui/button";
import { DatePicker } from "../../../../components/ui/datePicker";
import SelectComponent from "../../../../components/reusable/select/select";
import WeeksFormatter from "../../../../components/reusable/select/weeksToConsider";

interface Event {
  an_id: number;
  an_kpi_agre_type: string;
  an_kpi_desc: string;
  an_kpi_dt_ref_info: string;
  an_kpi_filter_field_info: string;
  an_kpi_filter_identif_info: string;
  an_kpi_id: number;
  an_kpi_name: string;
  an_kpi_num_week_range_info: number;
  an_kpi_qualit_type: string;
  an_kpi_source_info: string;
  an_kpi_time_field_info: string;
  an_kpi_value_info: string;
  zdata: string;
}

interface SelledItems {
  hide: string;
  kpi: string;
  natureza: string;
  tipo: string;
}

const EventsCreate = () => {
  const [tokenVerified, setTokenVerified] = useState(false);
  const navigate = useNavigate();

  // Callback function to update token verification status
  const handleTokenVerified = () => {
    setTokenVerified(true);
  };
  useTokenVerifier({
    onTokenVerified: handleTokenVerified,
    log: "CREATE EVENTS",
  });

  const { get, post } = useApi();
  const [events, setEvents] = useState<Event[]>([]);
  const [weeks, setWeeks] = useState<number>(6);
  const [loading, setLoading] = useState(true);
  const [newEvent, setNewEvent] = useState({ name: "", description: "" });
  const [selledItemsToDisplay, setSelledItemsToDisplay] = useState<
    SelledItems[]
  >([]);
  const [selledItems, setSelledItems] = useState<SelledItems[]>([]);

  const getMaxDate = () => {
    const date = new Date();
    date.setDate(date.getDate() - 7 * (weeks + 1)); // 7 days * weeks
    return date;
  };

  const [date, setDate] = useState<Date>(getMaxDate());
  const [type, setType] = useState("KPI Agregado");
  const [nature, setNature] = useState("Quantidades");
  const [selectedKpi, setSelectedKpi] = useState<string>("");

  const darkMode = useDarkMode();



  const fetchEvents = useCallback(async () => {
    setLoading(true);
    try {
      const response = await get("list_events");
      setEvents(response.data);
    } catch (error) {
      console.error(error);
      Toaster.show("Erro ao carregar listas de eventeamento", "error");
    } finally {
      setLoading(false);
    }
  }, []);

  const handleCreateEvent = async () => {
    try {
      const event = {
        name: newEvent.name,
        description: newEvent.description,
        refDate: date.toISOString(),
        type: type,
        nature: nature,
        kpi: selectedKpi,
        weeks: weeks,
      };
      await post(`create_event/`, event);
      fetchEvents();
      Toaster.show("Evento criado com sucesso", "success");
    } catch (error) {
      console.error("Erro ao criar analise:", error);
      Toaster.show("Erro ao criar evento", "error");
    } finally {

      setNewEvent({ name: "", description: "" });
    }
  };

  const handleFetchSelledItems = async () => {
    setLoading(true);
    try {
      const response = await get("event_options");
      setSelledItems(response.data);
      console.log("response.data", response.data);
    } catch (error) {
      console.error("Erro buscar event_options:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);

  useEffect(() => {
    if (selledItems.length === 0) {
      handleFetchSelledItems();
    }
  }, [selledItems]);

  useEffect(() => {
    if (selledItems) {
      setLoading(true);
      const summarizedSelledItems = selledItems.filter(
        (item) => item.tipo === type && item.natureza === nature
      );
      setSelledItemsToDisplay(summarizedSelledItems);
      setLoading(false);
    }
  }, [nature, selledItems, type]);

  useEffect(() => {
    setDate(getMaxDate());
  }, [weeks]);

  return (
    <>
      <DarkModeProvider>
        <div className="flex flex-col gap-8 mt-5">
          <div>
            <Label>Nome</Label>
            <Input
              type="text"
              placeholder="Nome do Evento"
              value={newEvent.name}
              onChange={(e) =>
                setNewEvent({ ...newEvent, name: e.target.value })
              }
            />
          </div>
          <div>
            <Label>Explicação do Evento</Label>
            <Input
              type="text"
              placeholder="Explicação do Evento"
              value={newEvent.description}
              onChange={(e) =>
                setNewEvent({
                  ...newEvent,
                  description: e.target.value,
                })
              }
            />
          </div>
          <div className="flex flex-col xl:flex-row justify-around gap-8">
            <div className="flex flex-col xl:items-center">
              <Label>Data de Referência do Evento</Label>
              <DatePicker
                date={date}
                setDate={setDate}
                maxDate={getMaxDate()}
              />
            </div>
            <div className="flex flex-col xl:items-center">
              <Label>Semanas a considerar</Label>
              <div className=" border rounded-md w-fit">
              <SelectComponent
                formatter={WeeksFormatter}
                darkMode={darkMode}
                onChange={setWeeks}
              />
              </div>
            </div>
          </div>
          <InfoComponent>
            <div>
              <p>
                Selecione o KPI que deseja analisar. Pode selecionar apenas 1
                KPI por evento.
              </p>
              <p>
                Selecione se pretende analisar por valor de vendas ou quantidade
                vendida.
              </p>
            </div>
          </InfoComponent>

          <div className="flex justify-between gap-8 flex-col 1600:flex-row">
            <div className="flex justify-between flex-col gap-8 oito:flex-row oito:gap-0">
              <div className="flex relative bg-zinc-200 w-fit rounded-full">
                <div
                  className={`rounded-full px-4 py-2 w-40 flex justify-center h-9 `}
                  onClick={() => setType("KPI Agregado")}
                >
                  <p
                    className={`z-[2] text-sm transition-colors duration-500 ease-in-out ${type === "KPI Agregado"
                        ? "text-gray-200"
                        : "text-gray-800"
                      }`}
                  >
                    KPI Agregado
                  </p>
                </div>
                <div
                  className={`rounded-full px-4 py-2 w-40 flex justify-center h-9 `}
                  onClick={() => setType("KPI Granular")}
                >
                  <p
                    className={`z-[2] text-sm transition-colors duration-500 ease-in-out ${type === "KPI Granular"
                        ? "text-gray-200"
                        : "text-gray-800"
                      }`}
                  >
                    KPI Granular
                  </p>
                </div>
                <div
                  className={`absolute bg-gray-900 top-0 w-40 h-9 rounded-full z-[1] transition-all duration-500 ease-in-out ${type === "KPI Agregado"
                      ? "translate-x-0"
                      : "translate-x-[100%]"
                    }`}
                ></div>
              </div>
              <div className="flex relative bg-zinc-200 w-fit rounded-full">
                <div
                  className={`rounded-full px-4 py-2 w-40 flex justify-center h-9 `}
                  onClick={() => setNature("Quantidades")}
                >
                  <p
                    className={`z-[2] text-sm transition-colors duration-500 ease-in-out ${nature === "Quantidades"
                        ? "text-gray-200"
                        : "text-gray-800"
                      }`}
                  >
                    Quantidades
                  </p>
                </div>
                <div
                  className={`rounded-full px-4 py-2 w-40 flex justify-center h-9 `}
                  onClick={() => setNature("Valor de Vendas")}
                >
                  <p
                    className={`z-[2] text-sm transition-colors duration-500 ease-in-out ${nature === "Valor de Vendas"
                        ? "text-gray-200"
                        : "text-gray-800"
                      }`}
                  >
                    Valor de Vendas
                  </p>
                </div>
                <div
                  className={`absolute bg-gray-900 top-0 w-40 h-9 rounded-full z-[1] transition-all duration-500 ease-in-out ${nature === "Quantidades"
                      ? "translate-x-0"
                      : "translate-x-[100%]"
                    }`}
                ></div>
              </div>
            </div>
          </div>
          <div className="overflow-auto scroll max-h-80">
            <table className="min-w-full divide-y divide-gray-100 shadow-2xl rounded">
              <thead className="bg-blue-dark rounded">
                <tr>
                  {[`${type} - ${nature}`, "Selecionar"].map((header) => (
                    <th
                      key={header}
                      className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider"
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody
                style={{ backgroundColor: darkMode ? "#262853" : "" }}
                className="bg-white divide-y divide-gray-200"
              >
                {selledItemsToDisplay.map((item, index) => (
                  <tr key={index}>
                    <td
                      className="px-6 py-2 whitespace-no-wrap text-gray-600"
                    >
                      {item.kpi}
                    </td>
                    <td
                      className="px-6 py-2 whitespace-no-wrap text-gray-600"
                    >
                      <input
                        checked={selectedKpi === item.kpi}
                        type="checkbox"
                        onChange={() => setSelectedKpi(item.kpi)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex justify-end gap-2">
            <Button
              onClick={handleCreateEvent}
              disabled={newEvent.name === "" || newEvent.description === ""}
            >
              Criar
            </Button>
          </div>
        </div>
        <Loading isOpen={loading} />
      </DarkModeProvider>
    </>
  );
};

export default EventsCreate;
