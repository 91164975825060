import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import {
  MdArrowBackIos,
  MdArrowForwardIos,
  MdSearch,
  MdDownload,
} from "react-icons/md";
import { useCat } from "../../../utils/contexts/CategoryContext";
import { axiosPrivate } from "../../../utils/api/axios";
import Toaster from "../Toaster";
import Modal from "../Modal";
import {
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
} from "../../../components/ui/table";

const MyTable = ({
  rowData,
  columnDefs,
  title,
  subText = "",
  darkMode,
  linkIndex = -1,
  trueLinkIndex = -1,
  link = "",
  setProductToFetch = null,
  consulta = 0,
  changedList = false,
  setChangedList = null,
  admin = false,
}) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalInfo, setModalInfo] = useState("");
  const { Categories } = useCat();
  const [selectedCategories, setSelectedCategories] = useState([]);
  const handleCategoryChange = (sku_id, event) => {
    setSelectedCategories((prevState) => {
      const existingItemIndex = prevState.findIndex(
        (item) => item.id === sku_id
      );

      if (existingItemIndex !== -1) {
        // If the item exists, replace its category with the new one
        const updatedItem = {
          ...prevState[existingItemIndex],
          category: event.target.value,
        };
        return prevState.map((item, index) =>
          index === existingItemIndex ? updatedItem : item
        );
      } else {
        // If the item doesn't exist, add a new one
        const newCategory = { id: sku_id, category: event.target.value };
        return [...prevState, newCategory];
      }
    });
  };

  const getValue = (item) => {
    const selectedCategory = selectedCategories.find(
      (categoryItem) => categoryItem.id === item.sku_id
    );
    return selectedCategory ? selectedCategory.category : item.sku_category;
  };

  const handleCategory = async () => {
    if (selectedCategories.length === 0) {
      Toaster.show("Mude primeiro uma categoria.", "error");
      return;
    }
    const body = {
      changes: selectedCategories,
    };
    try {
      const response = await axiosPrivate.post(
        `/sku_change_category/`,
        JSON.stringify(body)
      );
      if (response.status === 201) {
        Toaster.show("Categoria(s) alterada(s) com sucesso.", "success");
        setChangedList(!changedList);
      } else {
        Toaster.show("Ocorreu um erro, tente mais tarde.", "error");
      }
    } catch (error) {
      Toaster.show("Ocorreu um erro, tente mais tarde.", "error");
    }
  };
  const exportToPDF = () => {
    const doc = new jsPDF();

    autoTable(doc, {
      head: [columnDefs.map((column) => column.headerName)],
      body: rowData.map((item) =>
        columnDefs.map((column) =>
          typeof item[column.field] === "number"
            ? item[column.field].toFixed(2)
            : item[column.field]
        )
      ),
      margin: { top: 5, right: 5, bottom: 5, left: 5 },
    });

    doc.save(`${title}.pdf`);
  };

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [currentColumn, setCurrentColumn] = useState(1);

  const nextColumn = () => {
    if (currentColumn < columnDefs.length - 1) {
      setCurrentColumn(currentColumn + 1);
    }
  };

  const prevColumn = () => {
    if (currentColumn > 1) {
      setCurrentColumn(currentColumn - 1);
    }
  };

  if (!rowData || rowData.length === undefined) {
    return <div></div>;
    // return <div style={{
    //   position: 'relative',
    //   zIndex: '1',
    //   borderRadius: '10px',
    //   padding: '10px',
    //   paddingBottom: '50px',
    //   boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
    //   height: "100%",
    //   backgroundColor: darkMode ? '#262853' : 'white',
    //   color: darkMode ? '#ffffff' : 'black',
    //   display: "flex",
    //   justifyContent: "center",
    //   alignItems: "center"
    // }}><p>Loading...</p></div>;
  }
  if (rowData.length === 0) {
    return <div></div>;
    // return <div style={{
    //   position: 'relative',
    //   zIndex: '1',
    //   borderRadius: '10px',
    //   padding: '10px',
    //   paddingBottom: '50px',
    //   boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
    //   height: "100%",
    //   backgroundColor: darkMode ? '#262853' : 'white',
    //   color: darkMode ? '#ffffff' : 'black',
    //   display: "flex",
    //   justifyContent: "center",
    //   alignItems: "center"
    // }}><p>No data to display!!!</p></div>;
  }

  function renderTableCell(columnField, item, colIndex) {
    var string = columnField.toString();

    if (link != "") {
      if (link.includes("id")) {
        let id = item.an_id == undefined ? item.id : item.an_id;
        return (
          <TableCell className="px-6 py-2 whitespace-no-wrap" key={colIndex}>
            <Link
              to={`${link}${id}`}
              style={{ color: darkMode ? "white" : "black" }}
            >
              {item[columnField]}
            </Link>
          </TableCell>
        );
      }
    }

    if (linkIndex !== -1 && colIndex === linkIndex) {
      return (
        <TableCell className="px-6 py-2 whitespace-no-wrap" key={colIndex}>
          <Link
            to={`${link}${
              item[
                trueLinkIndex !== -1
                  ? columnDefs[trueLinkIndex].field
                  : columnField
              ]
            }`}
            style={{ color: darkMode ? "white" : "black" }}
          >
            {item[columnField]}
          </Link>
        </TableCell>
      );
    } else if (columnField === "variation" || columnField === "Dif %") {
      const variation = item[columnField];
      //const variationIcon = variation > 0 ? trendingUp : trendingDown;
      return (
        <TableCell className="px-6 py-2 whitespace-no-wrap" key={colIndex}>
          {variation}%{/*  <IonIcon icon={variationIcon} />  */}
        </TableCell>
      );
    } else if (
      (string.includes("price") ||
        string.includes("custo_estimado") ||
        string.includes("cost")) &&
      typeof item[columnField] === "number"
    ) {
      return (
        <TableCell className="px-6 py-2 whitespace-no-wrap" key={colIndex}>
          {item[columnField].toFixed(2) + "€"}
        </TableCell>
      );
    } else if (columnField === "log_obs") {
      const correspondingColumn = columnDefs.find(
        (column) => column.field === columnField
      );
      // setModalTitle(correspondingColumn ? correspondingColumn.headerName : '');
      // setModalInfo(item[columnField]);

      return (
        <TableCell className="px-6 py-2 whitespace-no-wrap" key={colIndex}>
          <button
            aria-label="search"
            className="hover:scale-125 transition-transform duration-300"
            onClick={() => {
              setShowModal(true);
              setModalTitle(
                correspondingColumn ? correspondingColumn.headerName : ""
              );
              setModalInfo(item[columnField]);
            }}
          >
            <MdSearch title="Consultar" />
          </button>
        </TableCell>
      );
    } else if (columnField === "url" && item.url) {
      return (
        <TableCell className="px-6 py-2 whitespace-no-wrap" key={colIndex}>
          <button
            aria-label="search"
            className="hover:scale-125 transition-transform duration-300"
            onClick={() => window.open(item.url)}
          >
            <MdSearch title="Consultar" />
          </button>
        </TableCell>
      );
    } else if (columnField === "consultarItem") {
      return (
        <TableCell className="px-6 py-2 whitespace-no-wrap" key={colIndex}>
          <button
            aria-label="search"
            className="hover:scale-125 transition-transform duration-300"
            onClick={() => setProductToFetch(item.sku_id || item.ingr_id)}
          >
            <MdSearch size={24} title="Consultar" />
          </button>
        </TableCell>
      );
    } else if (columnField === "sku_category" && consulta === 5 && admin) {
      return (
        <TableCell className="px-6 py-2 whitespace-no-wrap" key={colIndex}>
          <select
            value={getValue(item)}
            className="p-2 rounded-xl bg-transparent outline-none"
            onChange={(event) => handleCategoryChange(item.sku_id, event)}
          >
            {Categories.map((category, index) => (
              <option key={index} value={category}>
                {category}
              </option>
            ))}
          </select>
        </TableCell>
      );
    } else {
      return (
        <TableCell className="px-6 py-2 whitespace-no-wrap" key={colIndex}>
          {item[columnField]}
        </TableCell>
      );
    }
  }

  return (
    <div
      className="myTable"
      style={{
        position: "relative",
        zIndex: "1",
        borderRadius: "10px",
        padding: "5px",
        height: "100%",
        display: "flex",
        alignItems: "center",
        backgroundColor: darkMode ? "#262853" : "white",
        color: darkMode ? "#ffffff" : "black",
        justifyContent: "center",
      }}
    >
      <div
        className="componentTable"
        style={{ width: "90%", paddingBottom: "10px" }}
      >
        <button
          className="hover:scale-125 transition-transform duration-300"
          onClick={exportToPDF}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            backgroundColor: "transparent",
            color: darkMode ? "#ffffff" : "black",
          }}
        >
          <MdDownload title="DownLoad" size={24} />
        </button>
        {consulta === 5 && admin && (
          <div className="p-4">
            <button
              onClick={() => handleCategory()}
              style={{ background: darkMode ? "#422655" : "" }}
              className="ml-auto text-white bg-blue-dark p-3 rounded hover:scale-110 transition-transform duration-300"
            >
              Alterar Categoria(s)
            </button>
          </div>
        )}
        <h2
          className="font-semibold mt-3 mb-1"
          style={{ color: darkMode ? "#ffffff" : "black" }}
        >
          {title}
          <p className="text-sm text-zinc-500">{subText}</p>
        </h2>
        {width < 1100 && (
          <div>
            <div style={{ textAlign: "center" }}>
              {currentColumn > 1 && (
                <button onClick={prevColumn} aria-label="Previous column">
                  <MdArrowBackIos title="Coluna Anterior" size={24} />
                </button>
              )}
            </div>
            <div style={{ textAlign: "center" }}>
              {currentColumn < columnDefs.length - 1 && (
                <button onClick={nextColumn} aria-label="Next column">
                  <MdArrowForwardIos title="Coluna Seguinte" size={24} />
                </button>
              )}
            </div>
          </div>
        )}
        <Table>
          <TableHeader>
            <TableRow>
              {width > 1100 ? (
                columnDefs.map((column, index) => (
                  <TableCell
                    key={index}
                    style={{
                      backgroundColor: darkMode ? "#422653" : "",
                      color: darkMode ? "white" : "",
                    }}
                  >
                    {column.headerName}
                  </TableCell>
                ))
              ) : (
                <>
                  <TableCell
                    style={{
                      backgroundColor: darkMode ? "#422653" : "",
                      color: darkMode ? "white" : "",
                    }}
                  >
                    {columnDefs[0].headerName}
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: darkMode ? "#422653" : "",
                      color: darkMode ? "white" : "",
                    }}
                  >
                    {columnDefs[currentColumn].headerName}
                  </TableCell>
                </>
              )}
            </TableRow>
          </TableHeader>
          <TableBody>
            {rowData.map((item, rowIndex) =>
              width < 1100 ? (
                <TableRow key={rowIndex}>
                  {renderTableCell(columnDefs[0].field, item, 0)}
                  {renderTableCell(
                    columnDefs[currentColumn].field,
                    item,
                    currentColumn
                  )}
                </TableRow>
              ) : (
                <TableRow key={rowIndex}>
                  {columnDefs.map((column, colIndex) =>
                    renderTableCell(column.field, item, colIndex)
                  )}
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
        {showModal && (
          <Modal isOpen={showModal} setIsOpen={setShowModal}>
            <div
              style={{
                padding: "10px",
                overflowY: "auto",
                position: "relative",
              }}
            >
              <h2>{modalTitle}</h2>
              <p>{modalInfo}</p>
              <button
                style={{ position: "absolute", top: "10px", right: "10px" }}
                onClick={() => setShowModal(false)}
              >
                X
              </button>
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default MyTable;
