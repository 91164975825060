// React
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// Hooks
import useTokenVerifier from "../../../../utils/hooks/useTokenVerifier";
import { useApi } from "../../../../utils/hooks/useAxiosPrivate";
import useDarkMode from "../../../Profile/usedarkmode";

// Components
import Page from "../../page";
import RecipeForm from "./RecipeCreateModal";
import AssignProd from "./RecipeAssignProdModal";
import InfoComponent from "../../../../components/reusable/info/info.component";
import Loading from "../../../../components/reusable/loading/loading";
import Paginate from "../../../../components/reusable/pagination/paginate";
import Toaster from "../../../../components/reusable/Toaster";
import { Button } from "../../../../components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../components/ui/table";
import {
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../../components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../components/ui/dialog";

// Icons
import { IoAdd, IoRestaurant } from "react-icons/io5";
import { FaTrash, FaPencilAlt, FaPlus } from "react-icons/fa";
import { BiFoodMenu } from "react-icons/bi";

const RecipeListPage = () => {
  const [tokenVerified, setTokenVerified] = useState(false);

  // Callback function to update token verification status
  const handleTokenVerified = () => {
    setTokenVerified(true);
  };
  useTokenVerifier({
    onTokenVerified: handleTokenVerified,
    log: "RECIPES PAGE",
  });

  const { get, post } = useApi();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [modalCreate, setModalVisibleCreate] = useState(false);
  const [modalEdit, setModalVisibleEdit] = useState(false);
  const [modalAddProd, setModalAddProd] = useState(false);
  const [editable, setEditable] = useState(0);
  const [changedList, setChangedList] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isOpenDelete, setIsOpenDelete] = useState(-1);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await get(`recipe_list?page=${currentPage}`);
        setData(response.data);
        setTotalPages(response.data["results"].total_pages);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };
    fetchData();
  }, [currentPage, changedList]);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("pt-PT", {
      hour: "numeric",
      minute: "numeric",
    });
    return formattedDate;
  }

  const deleteRecipe = async (recipe_id: string) => {
    try {
      const response = await post(
        `/recipe_delete/`,
        JSON.stringify({ recipe_id: recipe_id })
      );

      if (response.status === 200) {
        Toaster.show("Receita apagada com sucesso.", "success");
        setLoading(true);
        setChangedList(!changedList);
      } else {
        Toaster.show("Erro ao apagar receita.", "error");
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      Toaster.show(
        "Erro ao apagar receita. Tente novamente mais tarde.",
        "error"
      );
    }
  };
  const darkMode = useDarkMode();

  // Function to handle dialog close
  const handleDialogClose = () => {
    setModalVisibleCreate(false);
    setModalVisibleEdit(false);
    setModalAddProd(false);
  };

  return (
    <Page title={"Gerir Receitas"} icon={<IoRestaurant size={32} />}>
      {tokenVerified ? (
        <div>
          {data && (
            <div className="w-[80%] m-auto text-black">
              {/* Header Section */}
              <div className="flex items-center justify-between mt-10">
                <CardHeader>
                  <CardTitle className="text-4xl font-semibold text-blue-dark">
                    Receitas
                  </CardTitle>
                  <CardDescription>
                    Consulte e edite as receitas do seu restaurante.
                  </CardDescription>
                </CardHeader>

                <Dialog>
                  <DialogTrigger asChild>
                    <button>
                      <Button onClick={() => setModalVisibleCreate(true)}>
                        <IoAdd size={16} className="mr-2" /> Receita
                      </Button>
                    </button>
                  </DialogTrigger>
                  <DialogContent className="w-[80vw] max-w-[1000px] h-[80vh] max-h-[800px] overflow-y-auto">
                    {" "}
                    {/* Increased width to 80vw and set a max width */}
                    <DialogHeader>
                      <DialogTitle>Criar Receita</DialogTitle>
                      <div className="pt-5">
                        <RecipeForm
                          modalCloser={handleDialogClose}
                          change={changedList}
                          setChange={setChangedList}
                        />
                      </div>
                    </DialogHeader>
                  </DialogContent>
                </Dialog>
              </div>

              {/* Info Section */}
              <InfoComponent text="">
                <p>
                  Lista de receitas do restaurante com classe correspondente. A
                  cada receita deve ser associado um produto da carta, deste
                  modo a aplicação monitoriza o desempanho de vendas da receita.
                </p>
              </InfoComponent>

              {/* Recipe Table */}
              {data && data["results"]["recipes"].length > 0 ? (
                <Table>
                  <TableHeader className="bg-white">
                    <TableRow>
                      <TableHead>Nome</TableHead>
                      <TableHead>Classe</TableHead>
                      <TableHead>Produto da carta</TableHead>
                      <TableHead>Estado</TableHead>
                      <TableHead>Date</TableHead>
                      <TableHead>Actions</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {data["results"]["recipes"].map((item, index) => (
                      <TableRow key={index} className="bg-white">
                        <TableCell>
                          <Link
                            to={`/app/menu/recipe?id=${item.recipe_id}`}
                            className="hover:scale-105 transition-transform duration-300"
                          >
                            {item.recipe_name}
                          </Link>
                        </TableCell>
                        <TableCell>{item.recipe_class}</TableCell>
                        <TableCell>
                          {!data["results"]["prods"][index]?.rest_prod_desc ? (
                            <Dialog>
                              <DialogTrigger asChild>
                                <Button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setModalAddProd(true);
                                    setEditable(item.recipe_id);
                                  }}
                                  className="hover:bg-gray-200 bg-gray-100 text-gray-600 text-xs transition-transform duration-300"
                                >
                                  {"Adicionar produto..."}
                                </Button>
                              </DialogTrigger>
                              <DialogContent className="w-[80vw] max-w-[1000px] h-[80vh] max-h-[800px] overflow-y-auto">
                                <DialogHeader>
                                  <DialogTitle>Associar Produtos</DialogTitle>
                                  <AssignProd
                                    recipe_id={editable}
                                    modalCloser={handleDialogClose}
                                    change={changedList}
                                    setChange={setChangedList}
                                  />
                                </DialogHeader>
                              </DialogContent>
                            </Dialog>
                          ) : (
                            data["results"]["prods"][index]?.rest_prod_desc
                          )}
                        </TableCell>
                        <TableCell
                          className={darkMode ? "text-white" : "text-black"}
                        >
                          {item.recipe_status}
                        </TableCell>
                        <TableCell
                          className={darkMode ? "text-white" : "text-black"}
                        >
                          {formatDate(item.ztimestamp)}
                        </TableCell>
                        <TableCell>
                          <div className="flex space-x-4">
                            <BiFoodMenu
                              title="Ver Ficha Tecnica"
                              className={`text-blue-dark text-xl ${darkMode ? "text-white" : "text-black"
                                } cursor-pointer`}
                            />
                            <Dialog>
                              <DialogTrigger asChild>
                                <FaPencilAlt
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setEditable(item.recipe_id);
                                  }}
                                  title="Editar"
                                  className="text-gray-600 hover:text-gray-800 text-xl cursor-pointer"
                                />
                              </DialogTrigger>
                              <DialogContent className="w-[80vw] max-w-[1000px] h-[80vh] max-h-[800px] overflow-y-auto">
                                <DialogHeader>
                                  <DialogTitle>Editar Receita</DialogTitle>
                                  <RecipeForm
                                    recipe_id={editable}
                                    modalCloser={handleDialogClose}
                                    change={changedList}
                                    setChange={setChangedList}
                                  />
                                </DialogHeader>
                              </DialogContent>
                            </Dialog>
                            <FaTrash
                              onClick={(e) => {
                                e.stopPropagation();
                                setIsOpenDelete(item.recipe_id);
                              }}
                              title="Eliminar"
                              className="text-red-300 hover:text-red-500 text-xl cursor-pointer"
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                <div className="text-center text-2xl text-blue-dark">
                  Não existem Receitas.
                </div>
              )}
            </div>
          )}

          {/* Pagination */}
          {totalPages >= 2 && (
            <div className="flex justify-center pb-12">
              <Paginate
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPages={totalPages}
                loading={!data}
              />
            </div>
          )}


          <Loading isOpen={loading} />
        </div>
      ) : (
        <Loading isOpen={true} />
      )}
    </Page>
  );
};

export default RecipeListPage;
