import React, { useState, useEffect } from "react";
import useAxiosPrivate from "../../../../utils/hooks/useAxiosPrivate";
import Toaster from "../../../../components/reusable/Toaster";
import { Input } from "../../../../components/ui/input";
import { Button } from "../../../../components/ui/button";
import { Label } from "../../../../components/ui/label";

interface RestaurantSetupProps {
  onPrevious?: () => void;
  onSubmit?: () => void;
}

const RestaurantSetup: React.FC<RestaurantSetupProps> = ({
  onPrevious,
  onSubmit,
}) => {
  const axiosPrivate = useAxiosPrivate();
  const [loading, setLoading] = useState(false);
  const [change, setChange] = useState(false);

  const [restaurant, setRestaurant] = useState<any>({});
  const [rest_name, setRest_name] = useState("");
  const [rest_nicp, setRest_nicp] = useState("");
  const [rest_address, setRest_address] = useState("");
  const [rest_email, setRest_email] = useState("");
  const [rest_postal_code, set_postal_code] = useState("");
  const [rest_city, setRest_city] = useState("");
  const [rest_country, setRest_country] = useState("");
  const [rest_contact_phone, setRest_contact_phone] = useState("");

  useEffect(() => {
    const getRestaurant = async () => {
      setLoading(true);
      try {
        const response = await axiosPrivate.get("restaurant");
        const restaurantData = Array.isArray(response.data)
          ? response.data[0]
          : response.data;
        setRestaurant(restaurantData);
        setRest_name(restaurantData.rest_name);
        setRest_nicp(restaurantData.rest_nipc);
        setRest_address(restaurantData.rest_address);
        setRest_email(restaurantData.rest_email);
        set_postal_code(restaurantData.rest_postal_code);
        setRest_city(restaurantData.rest_city);
        setRest_country(restaurantData.rest_country);
        setRest_contact_phone(restaurantData.rest_contact_phone);
      } catch (error) {
        console.error("error", error);
      }
      setLoading(false);
    };

    getRestaurant();
  }, [change]);

  const handleRestaurantSubmit = async () => {
    setLoading(true);

    const data = {
      rest_name: rest_name,
      rest_nipc: rest_nicp,
      rest_address: rest_address,
      rest_email: rest_email,
      rest_postal_code: rest_postal_code,
      rest_city: rest_city,
      rest_country: rest_country,
      rest_contact_phone: rest_contact_phone,
    };

    try {
      const response = await axiosPrivate.post(
        "/restaurant-update/",
        JSON.stringify(data)
      );

      if (response.status === 200 || response.status === 201) {
        Toaster.show("Restaurante atualizado com sucesso.", "success");
        setChange(!change);
        if (onSubmit) {
          onSubmit();
        }
      } else {
        Toaster.show("Erro ao atualizar restaurante.", "error");
      }
    } catch (error) {
      Toaster.show("Erro ao atualizar restaurante.", "error");
    }
    setLoading(false);
  };

  const validateInputs = () => {
    const fields = [
      { name: "rest_name", value: rest_name },
      { name: "rest_nicp", value: rest_nicp },
      { name: "rest_address", value: rest_address },
      { name: "rest_email", value: rest_email },
      { name: "rest_postal_code", value: rest_postal_code },
      { name: "rest_city", value: rest_city },
      { name: "rest_country", value: rest_country },
    ];

    const missingFields = fields.filter((field) => !field.value);

    if (missingFields.length > 0) {
      console.log(
        "Missing fields:",
        missingFields.map((field) => field.name).join(", ")
      );
      return false;
    }

    return true;
  };

  return (
    <div>
      <div className="mt-8 space-y-10">
        <div>
          <Label htmlFor="rest_name">Nome do Restaurante</Label>
          <Input
            name="rest_name"
            placeholder={restaurant.rest_name}
            value={rest_name}
            onChange={(e) => setRest_name(e.target.value)}
          />
        </div>
        <div>
          <Label htmlFor="rest_nicp">NIPC do Restaurante</Label>
          <Input
            name="rest_nicp"
            type="number"
            min={0}
            placeholder={restaurant.rest_nipc}
            value={rest_nicp}
            onChange={(e) => setRest_nicp(e.target.value)}
          />
        </div>
        <div>
          <Label htmlFor="rest_address">Endereço do Restaurante</Label>
          <Input
            name="rest_address"
            placeholder={restaurant.rest_address}
            value={rest_address}
            onChange={(e) => setRest_address(e.target.value)}
          />
        </div>
        <div>
          <Label htmlFor="rest_email">Email do Restaurante</Label>
          <Input
            name="rest_email"
            placeholder={restaurant.rest_email}
            value={rest_email}
            onChange={(e) => setRest_email(e.target.value)}
          />
        </div>
        <div>
          <Label htmlFor="rest_postal_code">Código Postal</Label>
          <Input
            name="rest_postal_code"
            placeholder={restaurant.rest_postal_code}
            value={rest_postal_code}
            onChange={(e) => set_postal_code(e.target.value)}
          />
        </div>
        <div>
          <Label htmlFor="rest_city">Cidade</Label>
          <Input
            name="rest_city"
            placeholder={restaurant.rest_city}
            value={rest_city}
            onChange={(e) => setRest_city(e.target.value)}
          />
        </div>
        <div>
          <Label htmlFor="rest_country">País</Label>
          <Input
            name="rest_country"
            placeholder={restaurant.rest_country}
            value={rest_country}
            onChange={(e) => setRest_country(e.target.value)}
          />
        </div>
      </div>

      <div className="flex justify-between mt-5 b-2">
        {onPrevious && <Button onClick={onPrevious}>Anterior</Button>}
        <Button
          className="ml-auto"
          onClick={handleRestaurantSubmit}
          disabled={loading || !validateInputs()}
        >
          Guardar
        </Button>
      </div>
    </div>
  );
};

export default RestaurantSetup;
