import React, { useEffect } from "react";

const PricingTable = () => {
  useEffect(() => {
    // Add the Stripe script dynamically
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;
    document.body.appendChild(script);

    // Create the pricing table element
    const pricingTableElement = document.createElement("stripe-pricing-table");
    pricingTableElement.setAttribute(
      "pricing-table-id",
      "prctbl_1QGgf3Rtm03rkjycCXCvw94K"
    );
    pricingTableElement.setAttribute(
      "publishable-key",
      "pk_live_51QGMs3Rtm03rkjycc6ysYjdn4NTZ89iA8vyjxWxl2GDl5unaOVEGTDtP0eXkTi5ZusllfEnTZRjyuzlppqPQd8HW00aENysaNB"
    );

    // Append the pricing table element to the div
    document
      .getElementById("stripe-pricing-table")
      .appendChild(pricingTableElement);
  }, []);

  return (
    <div className="flex items-center justify-center w-full h-screen">
      <div className="bg-white rounded-lg p-10">
        <div id="stripe-pricing-table"></div>
      </div>
    </div>
  );
};

export default PricingTable;
