import { useEffect, useState } from "react";
import { CatProvider } from "../../utils/contexts/CategoryContext";
import { ClassProvider } from "../../utils/contexts/ClassContext";
import { useSidebar } from "../../utils/contexts/SideBarContext";
import Loading from "../../components/reusable/loading/loading";

const NoChartPages = [
  "Consultas",
]

const Page = ({ children, title = null, icon = null }) => {
  /*const LOGS_URL = "/create_log"
  const AUTH_URL = "/auth/jwt/verify"
 
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const verifyToken = async () => {
      console.log("verifyToken!")
      try {
        const response = await axiosPrivate.post(AUTH_URL, {
          token: auth?.accessToken,
        }, {
          signal: controller.signal,
        });
 
        if (response.status < 300) {
          onTokenVerified();
          const logData = {
            username: '',
            log_type: {log},
            log_oper_status: 'SUCCESS',
            log_oper_descr: 'teste',
            log_obs: 'dummyObs',
          };
          //console.log("Sending create log request!");
          const responseLogs = await axiosPrivate.post(LOGS_URL, logData, {
 
          });
          //console.log("Response status: ", response.status);
        }
      } catch (error) {
        console.error('Error refreshing token (Home): ', error);
        const logData = {
          username: 'Anonymous',
          log_type: {log},
          log_oper_status: 'TOKEN INVALID',
          log_oper_descr: 'teste',
          log_obs: 'dummyObs',
        };
        const responseLogs = await axiosPrivate.post(LOGS_URL, logData, {
        });
        Toaster.show('Ocorreu um erro e foi necessário reiniciar a página. Pedimos desculpa pelo incómodo.')
        history.goBack();
        window.location.reload();
      }
    }
 
    //if (auth && Object.values(auth).some(value => typeof value === 'string' && value.trim() !== '')) {
    verifyToken();
    //}
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [auth]);*/
  const { sidebarOpen } = useSidebar();
  const [fade, setFade] = useState(false);
  useEffect(() => {
    setFade(true);
    const timer = setTimeout(() => setFade(false), 750);
    return () => clearTimeout(timer);
  }, [sidebarOpen]);

  return (
    <div className="w-full h-screen bg-zinc-100">
      <CatProvider>
        <ClassProvider>
          <div
            className={`w-full bg-zinc-100 transition-all duration-500 p-6 mil:p-10 mil:pb-20 ${
              !NoChartPages.includes(title) && fade ? "opacity-0" : "opacity-100"
            }`}
          >
            {children}
          </div>
          <Loading isOpen={fade} />
        </ClassProvider>
      </CatProvider>
    </div>
  );
};

export default Page;
