import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useUser } from "../../utils/contexts/UserContext";
import { AiFillHome } from "react-icons/ai";
import {
  FaRegClipboard,
  FaShoppingBasket,
  FaChartLine,
  FaSearch,
  FaRegCalendarAlt,
  FaMoneyBillAlt,
  FaUtensils,
  FaClipboardCheck,
  FaCheckCircle,
} from "react-icons/fa";
import {
  MdEvent,
  MdAttachMoney,
  MdTrendingUp,
  MdPersonSearch,
  MdCreateNewFolder,
  MdAddCircleOutline,
  MdSettings,
} from "react-icons/md";
import {
  GiCookingPot,
  GiMoneyStack,
  GiCook,
  GiRecycle,
  GiBookCover,
} from "react-icons/gi";
import { FiUploadCloud } from "react-icons/fi";

import { IoMdArrowBack, IoMdArrowDropdown } from "react-icons/io";
import { IoMdArrowDropup } from "react-icons/io";

import "../../assets/css/customScroll.styles.css";
import ProfileComponent from "./ProfileComponent";
import { RxHamburgerMenu } from "react-icons/rx";
import "./sidebar.css";

interface SidebarProps {
  isSidebarOpen: boolean;
  setIsSidebarOpen: (isSidebarOpen: boolean) => void;
}

const Sidebar = ({ isSidebarOpen, setIsSidebarOpen }: SidebarProps) => {
  const { user } = useUser();
  const imageUrl = `${process.env.PUBLIC_URL}/logo3.png`;
  const location = useLocation();
  const [showLabelHome, setShowLabelHome] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isPlanningOpen, setIsPlanningOpen] = useState(false);
  const [isDashboardOpen, setIsDashboardOpen] = useState(false);

  const navItems = {
    common: [
      { to: "/app/forecasts", label: "Forecasts", icon: FaChartLine },
      {
        to: "/app/customers",
        label: "Customers Analysis",
        icon: MdPersonSearch,
      },
      { to: "/app/pricing", label: "Pricing", icon: FaMoneyBillAlt },
      { to: "/app/events", label: "Events", icon: MdEvent },
      { to: "/app/uploadsaft", label: "Upload SAFT", icon: FiUploadCloud },
      { to: "/app/consultas", label: "Queries", icon: FaSearch },
      { to: "/app/dataFilling", label: "Data Filling", icon: FaClipboardCheck }, //admin
      { to: "/payments", label: "Pricing", icon: FaMoneyBillAlt },
    ],
    dashboards: [
      { to: "/app/general", label: "General", icon: FaRegClipboard },
      { to: "/app/ownbasket", label: "Own Basket", icon: FaShoppingBasket },
      {
        to: "/app/foodcost/recipes",
        label: "Food Cost Recipes",
        icon: GiCookingPot,
      },
      { to: "/app/margin", label: "Margin Analysis", icon: GiMoneyStack },
      {
        to: "/app/foodcostAnalysis",
        label: "Food Cost Analysis",
        icon: FaUtensils,
      },
      {
        to: "/app/sales/short",
        label: "Sales Short Term",
        icon: MdAttachMoney,
      },
      { to: "/app/sales/long", label: "Sales Long Term", icon: MdTrendingUp },
    ],
    planning: [
      { to: "/app/planning", label: "Planning", icon: FaRegCalendarAlt },
      {
        to: "/app/planning/create",
        label: "Planning Create",
        icon: MdCreateNewFolder,
      },
      // {
      //   to: "/app/planning/custom/:id",
      //   label: "Custom Planning",
      //   icon: AiOutlineProject,
      // },
    ],
    menu: [
      { to: "/app/menu", label: "Recipe List", icon: GiBookCover },
      {
        to: "/app/menu/ingred/create",
        label: "Create Ingredient",
        icon: MdAddCircleOutline,
      },
      {
        to: "/app/menu/reprocess_ingreds",
        label: "Reprocess Ingredients",
        icon: GiRecycle,
      },
      {
        to: "/app/menu/reprocess_recipes",
        label: "Reprocess Recipes",
        icon: GiCook,
      },
      // { to: "/app/menu/recipe", label: "Recipe Detail", icon: FaListAlt },
      { to: "/app/menu/recipe/tec", label: "Recipe Tec", icon: MdSettings },
      // {
      //   to: "/app/menu/ingredient",
      //   label: "Ingredient Detail",
      //   icon: GiFruitBowl,
      // },
      {
        to: "/app/menu/validate_recipes",
        label: "Validate Recipes",
        icon: FaCheckCircle,
      },
    ],
  };

  const renderNavItems = (items) => {
    const matchingItems = items.filter(item => location.pathname.includes(item.to));
    const activeItem = matchingItems.reduce((prev, current) => 
      current.to.length > prev.to.length ? current : prev, { to: '' });

    return items.map((item) => (
      <Link
        key={item.to}
        to={item.to}
        onClick={() => {
          setIsMenuOpen(false);
        }}
      >
        <li
          className={`px-4 py-3 flex gap-2 items-center ${activeItem.to === item.to ? "bg-zinc-100" : "hover:bg-zinc-100"
            }`}
        >
          <item.icon className="w-6 h-6 rounded" />
          <p className="text-black">{item.label}</p>
        </li>
        <hr />
      </Link>
    ));
  };

  return (
    <>
      {user && (
        <>
          <div
            className={`transform transition-all duration-700 ease-in-out ${isSidebarOpen ? "w-0" : "w-full z-[99999]"
              } fixed px-5 py-5`}
          >
            <nav
              className={`bg-white shadow-md rounded-xl flex items-center justify-between h-20`}
            >
              <div className="flex gap-x-10">
                <button onClick={() => setIsSidebarOpen(true)}>
                  <RxHamburgerMenu
                    size={30}
                    className="ml-5 text-2xl cursor-pointer"
                  />
                </button>
                <Link to="/app/homepage" className="flex items-center">
                  <img
                    className="h-9 rounded-full custom-color"
                    src={imageUrl}
                    alt="Logo"
                  />
                </Link>
              </div>
              <div className="mr-5">
                <ProfileComponent />
              </div>
            </nav>
          </div>
          <nav
            className={`fixed top-0 left-0 bottom-0 w-72 bg-white shadow-md transform ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"
              } transition-transform duration-700 ease-in-out`}
          >
            <div className="h-full flex flex-col">
              <div className="p-4 flex justify-between items-center">
                <div
                  className="relative"
                  onMouseEnter={() => setShowLabelHome(true)}
                  onMouseLeave={() => setShowLabelHome(false)}
                >
                  <Link to="/app/homepage" className="flex items-center">
                    <img
                      className="h-9 rounded-full custom-color"
                      src={imageUrl}
                      alt="Logo"
                    />
                  </Link>
                </div>
                <IoMdArrowBack
                  size={20}
                  className="text-2xl cursor-pointer"
                  onClick={() => setIsSidebarOpen(false)}
                />
              </div>
              <ul className="flex-grow overflow-y-auto scroll pr-2 pb-4 mt-8">
                <Link
                  to="/app/homepage"
                  onClick={() => {
                    setIsMenuOpen(false);
                  }}
                >
                  <li
                    className={`px-4 py-3 flex gap-2 items-center ${location.pathname === "/app/homepage"
                        ? "bg-zinc-100"
                        : "hover:bg-zinc-100"
                      }`}
                  >
                    <AiFillHome className="w-6 h-6 rounded" />
                    <p className="text-black">Homepage</p>
                  </li>
                </Link>
                <li
                  className="px-4 py-3 flex gap-2 items-center cursor-pointer hover:bg-zinc-100"
                  onClick={() => setIsDashboardOpen(!isDashboardOpen)}
                >
                  <FaRegClipboard className="w-6 h-6 rounded" />
                  <div className="flex gap-2 items-center justify-between w-full text-black">
                    <p>Dashboards</p>
                    {isDashboardOpen ? (
                      <IoMdArrowDropup size={32} title="Close Menu" />
                    ) : (
                      <IoMdArrowDropdown size={32} title="Open Menu" />
                    )}
                  </div>
                </li>
                <div className="pl-2">
                  {isDashboardOpen && renderNavItems(navItems.dashboards)}
                </div>
                <li
                  className="px-4 py-3 flex gap-2 items-center cursor-pointer hover:bg-zinc-100"
                  onClick={() => setIsPlanningOpen(!isPlanningOpen)}
                >
                  <FaRegCalendarAlt className="w-6 h-6 rounded" />
                  <div className="flex gap-2 items-center justify-between w-full text-black">
                    <p>Planning</p>
                    {isPlanningOpen ? (
                      <IoMdArrowDropup size={32} title="Close Menu" />
                    ) : (
                      <IoMdArrowDropdown size={32} title="Open Menu" />
                    )}
                  </div>
                </li>
                <div className="pl-2">
                  {isPlanningOpen && renderNavItems(navItems.planning)}
                </div>
                <li
                  className="px-4 py-3 flex gap-2 items-center cursor-pointer hover:bg-zinc-100"
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                >
                  <GiBookCover className="w-6 h-6 rounded" />
                  <div className="flex gap-2 items-center justify-between w-full text-black">
                    <p>Menu</p>
                    {isMenuOpen ? (
                      <IoMdArrowDropup size={32} title="Close Menu" />
                    ) : (
                      <IoMdArrowDropdown size={32} title="Open Menu" />
                    )}
                  </div>
                </li>
                <div className="pl-2">
                  {isMenuOpen && renderNavItems(navItems.menu)}
                </div>
                {renderNavItems(navItems.common)}
              </ul>
            </div>
          </nav>
        </>
      )}
    </>
  );
};

export default Sidebar;
