import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  ReactNode,
} from "react";
import { useApi } from "../../utils/hooks/useAxiosPrivate";

interface Restaurant {
  rest_site_id: number;
  rest_name: string;
  rest_nipc: number | null;
  rest_address: string | null;
  rest_city: string | null;
  rest_contact_phone: string | null;
  rest_country: string | null;
  rest_email: string;
  rest_latitude: number | null;
  rest_longitude: number | null;
  rest_postal_code: string | null;
  ztimestamp: string;
}

interface Profile {
  profile_id: number;
  first_name: string;
  last_name: string;
  phone: string | null;
  rest_site: number;
  restaurant: Restaurant;
  role: "MANAGER" | "CHEF" | "SALES";
  subscription: "FREE" | "PRO";
  user_id: number;
  ztimestamp: string;
}

interface User {
  id: number;
  email: string;
  profile: Profile;
  restaurant: Restaurant;
  username: string;
}

interface UserContextType {
  user: User | null;
  restaurant: Restaurant | null; // Added restaurant
  profile: Profile | null; // Added profile
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  setRestaurant: React.Dispatch<React.SetStateAction<Restaurant | null>>;
  setProfile: React.Dispatch<React.SetStateAction<Profile | null>>;
  fetchUser: () => Promise<void>;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<User | null>(null);
  const [restaurant, setRestaurant] = useState<Restaurant | null>(null);
  const [profile, setProfile] = useState<Profile | null>(null);
  
  const { get } = useApi();

  const fetchUser = async () => {
    try {
      console.log("Fetching user data");
      const response = await get("user/");
      console.log("User Context", response.data);
      setRestaurant(response.data.restaurant);
      setProfile(response.data.profile);
      setUser(response.data.user);
      
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };


  useEffect(() => {
    if(!user)
      fetchUser(); // Ensure this is called on mount
  }, [user]);

  return (
    <UserContext.Provider
      value={{ user, restaurant, profile, setUser, setRestaurant, setProfile, fetchUser }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  //if (context === undefined) {
  //  throw new Error("useUser must be used within a UserProvider");
  //}
  return context;
};
