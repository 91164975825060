// React
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";


// Hooks
import useDarkMode from "../../Profile/usedarkmode";
import { useApi } from "../../../utils/hooks/useAxiosPrivate";
import { useCat } from "../../../utils/contexts/CategoryContext";
import useTokenVerifier from "../../../utils/hooks/useTokenVerifier";

// Components
import Page from "../page";
import ChartComponent from "../../../components/reusable/charts/chartComponent";
import Loading from "../../../components/reusable/loading/loading";
import BackComponent from "../../../components/reusable/goBack/go-back-component";
import SelectComponent from "../../../components/reusable/select/select";
import CategoryFormatter from "../../../components/reusable/select/categories";

// icons / images
import { IoPlayForwardCircle } from "react-icons/io5";

const INITIAL_CHART_OPTIONS = {
  title: { text: "Loading ..." },
};

const CHART_IDS = ["q11", "q12", "q21", "q22", "q31", "q41"];

const ForecastView = () => {
  const [tokenVerified, setTokenVerified] = useState(false);

  // Callback function to update token verification status
  const handleTokenVerified = () => {
    setTokenVerified(true);
  };
  useTokenVerifier({
    onTokenVerified: handleTokenVerified,
    log: "FORECAST VIEW PAGE",
  });

  const { get } = useApi();
  const categories = useCat();
  const location = useLocation();
  const darkMode = useDarkMode();
  const [chartLoaded, setChartLoaded] = useState(false);
  const forecastN = new URLSearchParams(location.search).get("id");
  const [category, setCategory] = useState("");
  const [loading, setLoading] = useState(true);
  const chartRef = useRef(null);
  const [chartOptions, setChartOptions] = useState(
    Object.fromEntries(CHART_IDS.map((id) => [id, INITIAL_CHART_OPTIONS]))
  );
  function handleChartFinished() {
    if (chartRef.current) {
      chartRef.current.getEchartsInstance().resize();
    }
    setChartLoaded(true);
  }
  useEffect(() => {
    if (categories.Categories.length > 0) {
      setCategory(categories.Categories[0]);
    }
  }, [categories.Categories]);

  useEffect(() => {
    const controller = new AbortController();
    setLoading(true);

    const fetchChartData = async () => {
      try {
        const results = await Promise.all(
          CHART_IDS.map((id) =>
            get(
              `dash8_${id}/?${new URLSearchParams({
                an_id: forecastN,
                ...(id === "q41" && category ? { category } : {}),
              })}`,
              { signal: controller.signal }
            )
          )
        );

        setChartOptions((prevOptions) =>
          Object.fromEntries(
            CHART_IDS.map((id, index) => [id, results[index].data])
          )
        );
      } catch (error) {
        console.error("Error fetching chart data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchChartData();

    return () => controller.abort();
  }, [forecastN, category]);

  const getQ11Description = () => (
    <div>
      Este histograma representa a pearcentagem de variação do preço dos
      produtos. Os produtos que não sofreram variação de preço foram excluidos.
      Cada barra representa uma percentagem de variação de preço.
      <br />
      As barras têm uma diferença percentual de dois pontos. Quando maior for a
      barra, maior a frequência daqueles casos, isto é, maior é o número de
      produtos que variou a percentagem associada a essa barra.
      <br />
      Eixo horizontal; x - valores percentuais de variação
      <br />
      Eixo vertical; y - frequência = número de casos i.e. produtos
    </div>
  );

  return (
    <Page
      title={"Resultados do Forecast"}
      icon={<IoPlayForwardCircle size={32} />}
    >
      {tokenVerified ? (
        <>
          <BackComponent />
          <div className="ion-padding">
            <div>
              <div className="homePageText">
                <div
                  className="welcome"
                  style={{
                    color: darkMode ? "#f4f4f4" : "black",
                    paddingLeft: "3rem",
                  }}
                >
                  Resultados do Forecast {forecastN}
                </div>
              </div>

              <div className="grid xl:grid-cols-2 gap-4 padding">
                {["q11", "q12", "q21", "q22"].map((id) => (
                  <ChartComponent
                    chartRef={chartRef}
                    option={chartOptions[id]}
                    ChangeScale={false}
                    handleChartFinished={handleChartFinished}
                    graphDescription={
                      id === "q11" ? getQ11Description() : undefined
                    }
                  />
                ))}
                <ChartComponent
                  handleChartFinished={handleChartFinished}
                  chartRef={chartRef}
                  option={chartOptions.q31}
                  ChangeScale={false}
                />
                {categories.Categories.length > 0 && (
                  <>
                    <div className="xl:col-span-2">
                      <SelectComponent
                        formatter={CategoryFormatter}
                        darkMode={darkMode}
                        onChange={setCategory}
                      />
                    </div>
                    <ChartComponent
                      handleChartFinished={handleChartFinished}
                      chartRef={chartRef}
                      option={chartOptions.q41}
                      ChangeScale={false}
                    />
                  </>
                )}
              </div>
            </div>
            <Loading isOpen={loading} />
          </div>
        </>
      ) : (
        <Loading isOpen={true} />
      )}
    </Page>
  );
};

export default ForecastView;
