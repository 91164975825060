// React
import { useEffect, useState } from "react";

// Hooks
import useAxiosPrivate from "../../../../utils/hooks/useAxiosPrivate";
import useDarkMode from "../../../Profile/usedarkmode";

// Components
import InfoComponent from "../../../../components/reusable/info/info.component";
import Toaster from "../../../../components/reusable/Toaster";
import { Button } from "../../../../components/ui/button";
import Loading from "../../../../components/reusable/loading/loading";
import "../../../../assets/css/customScroll.styles.css";
import SearchBar from "../../../../components/ui/searchbar";

const AssignProd = ({ recipe_id = 0, modalCloser, setChange, change }) => {
  // loadingSetter
  const axiosPrivate = useAxiosPrivate();
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedProd, setselectedProd] = useState(undefined);
  const [searchItem, setSearchItem] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const getGraph = async (endpoint, params = {}) => {
      setLoading(true);
      const url = params
        ? `${endpoint}/?${Object.entries(params)
          .map(([key, value]) => `${key}=${value}`)
          .join("&")}`
        : `${endpoint}`;
      try {
        const response = await axiosPrivate.get(url, {
          signal: controller.signal,
        });
        setData(response.data);
        setLoading(false);
        // loadingSetter(false);
      } catch (error) {

        Toaster.show("Erro ao listar produtos.", "error");
        setLoading(false);
      }
    };
    if (searchItem !== "") {
      getGraph("rest_prods_list", { search: searchItem });
    }

    return () => {
      controller.abort();
    };
  }, [searchItem]);


  const handleCheckboxChange = (prod_id) => {
    setselectedProd(prod_id);
  };

  const handleAssign = async () => {
    // loadingSetter(true);
    if (!selectedProd) {
      Toaster.show("Selecione um produto", "error");
      // loadingSetter(false);
      return;
    }

    const prodToAssign = {
      rest_prod: selectedProd,
      recipe: recipe_id,
      ass_state: "ACTIVE",
    };

    try {
      const response = await axiosPrivate.post(
        `/prod_asign_create_or_update/`,
        JSON.stringify(prodToAssign)
      );

      if (response.status === 200 || response.status === 201) {
        Toaster.show("Produto associado com sucesso.", "success");
      } else {
        Toaster.show("Erro ao associar produto.", "error");
        // loadingSetter(false);
      }
    } catch (error) {
      console.error("Error:", error);
      Toaster.show("Erro ao associar produto.", "error");
      // loadingSetter(false);
    }
    setChange(!change);
  };



  const darkMode = useDarkMode();

  return (
    <>
      <div>
        <div>
          <div
            className="pt-6"
          >
            <SearchBar
              search={search}
              setSearch={setSearch} 
              setFetchSearch={setSearchItem}
              
             />
          </div>
        </div>

        <InfoComponent text="">
          <p>
            Associe um produto da sua carta à receita. Isto permite à aplicação
            estudar a performance da suas receitas.
            Pesquise por um produto para adicionar.
          </p>
        </InfoComponent>

        <div className="overflow-auto max-h-96 scroll">
          {data.length > 0 && (
            <table className="min-w-full divide-y divide-gray-200 shadow-2xl rounded">
              <thead className="bg-blue-dark text-white rounded">
                <tr>
                  <th
                    style={{ background: darkMode ? "#422655" : "" }}
                    className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider"
                  >
                    Nome
                  </th>
                  <th
                    style={{ background: darkMode ? "#422655" : "" }}
                    className="px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider"
                  >
                    Select
                  </th>
                </tr>
              </thead>
              <tbody
                style={{ backgroundColor: darkMode ? "#262853" : "" }}
                className="bg-white divide-y divide-gray-200"
              >
                {data.map((prod) => (
                  <tr key={prod.rest_prod_id}>
                    <td
                      style={{ color: darkMode ? "white" : "black" }}
                      className="px-6 py-2 whitespace-no-wrap text-gray-600"
                    >
                      {prod.rest_prod_desc}
                    </td>
                    <td
                      style={{ color: darkMode ? "white" : "black" }}
                      className="px-6 py-2 whitespace-no-wrap text-gray-600"
                    >
                      <input
                        type="checkbox"
                        checked={selectedProd === prod.rest_prod_id}
                        onChange={() => handleCheckboxChange(prod.rest_prod_id)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>

        {data.length > 0 && <div className="pt-6 flex justify-end">
          <Button
            type="submit"
            onClick={() => {
              modalCloser(false);
              handleAssign();
            }}
          >
            Associar produto da carta
          </Button>
        </div>}
        <Loading isOpen={loading} />

      </div>
    </>
  );
};

export default AssignProd;
